import React from 'react';
import { Modal, ModalBody, Button, Form, Row, Col, ModalHeader } from 'reactstrap';
import * as EmployeeAction from 'store/actions/employee';
import { getCountryOption } from 'store/actions/location';
import { useDispatch, useSelector } from 'react-redux';
import * as CurrencyAction from 'store/actions/currency';
import { useForm } from 'react-hook-form';
import { fetchWaybillNumber } from 'services/order';
import BasicInfoForm from './../basicInfoForm';
import ItemInfoForm from './../itemInfoForm';
import CostInfoForm from './../costInfoForm';
import { getCargoType } from 'utli';
import EntryGrid from 'components/Inputs/EntryGrid';
import ImageUploadContainer from './../ImageUploadContainer';
import AdressInputForm from 'pages/information/addressbook/inputForm';
import './add-dialog.scss';
import { t } from 'i18next';
import { NotificationManager } from 'react-notifications';

export default function AddDialog({ modalOpen, onToggle, onSubmit, editData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    getValues,
    watch,
  } = useForm();

  const [basicFreightError, setBasicFreightError] = React.useState(false);

  const departure = watch('basicinfo.createdBranch');
  const destination = watch('basicinfo.destination');

  const isDeparAndDestiEqual = departure !== undefined && departure === destination;

  React.useEffect(() => {
    if (isDeparAndDestiEqual) {
      NotificationManager.error('Departure and Destination cannot be the same');
    }
  }, [isDeparAndDestiEqual]);

  const submitData = async (values) => {
    if (isDeparAndDestiEqual) {
      NotificationManager.error('Departure and Destination cannot be the same');
      return;
    }

    if (values.basicinfo.shipment_type_name === 'Full Loading') {
      if (!values.costinfo.org_basic_freight) {
        setBasicFreightError(true);
        return;
      }
    }

    // reset()

    setBasicFreightError(false);

    const uploadFiles = {
      orderImageFiles: orderImageFiles,
      waybillImageFiles: waybillImageFiles,
      itemImageFiles: itemImageFiles,
    };

    setItemImageFiles([]);
    setOrderImageFiles([]);
    setWaybillImageFiles([]);

    onSubmit(values, uploadFiles);
  };
  const [orderImageFiles, setOrderImageFiles] = React.useState([]);
  const [itemImageFiles, setItemImageFiles] = React.useState([]);
  const [waybillImageFiles, setWaybillImageFiles] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState(null);

  const dispatch = useDispatch();
  const countries = useSelector((state) => state.location.countries);

  React.useEffect(() => {
    if (editData) {
      setValue('basicinfo.waybill_no', editData?.waybillnumber);
      setValue('basicinfo.shipment_type', editData?.shipment_type._id);
      setValue('basicinfo.shipping_mode', editData?.shipping_mode._id);
      setValue('basicinfo.delivery_mode', editData?.delivery_mode);
      setValue('basicinfo.delivery_place', editData?.delivery_place);
      setValue('basicinfo.createdBranch', editData?.createdBranch?._id);
      setValue('basicinfo.shipment_type_name', editData?.shipment_type.name);
      setValue('basicinfo.destination', editData?.destination._id);
      setValue('basicinfo.custom_clearence', editData?.custom_clearence?._id);
      setValue('basicinfo.shipping_date', editData?.shipping_date);
      setValue('iteminfo', editData?.item);

      setValue('iteminfo.itemtype', editData?.item?.itemtype?._id);
      setValue('costinfo', editData?.cost);
      setValue('costinfo.paymentType', editData?.cost.paymentType._id);
      if (editData?.cost.currency) setValue('costinfo.currency', editData?.cost.currency._id);
      setValue('senderinfo', editData.sender);
      setValue('senderinfo.code', editData.address_code);
      setValue('senderinfo.marketing_person', editData.sale_person?._id);

      setValue('receiverinfo', editData.receiver);

      let result = getCargoType(getValues('iteminfo.weight') / getValues('iteminfo.volume'));

      if (result === 'Light Cargo') {
        setValue('costinfo.qty_basic_freight_label', 'Cbm');
      } else {
        setValue('costinfo.qty_basic_freight_label', 'Kg');
      }
    } else {
      reset();
    }
  }, [editData, getValues, reset, setValue]);

  React.useEffect(() => {
    dispatch(EmployeeAction.getMarketingPerson());
    dispatch(CurrencyAction.getAll({}));
    dispatch(getCountryOption());
  }, [dispatch, EmployeeAction.getMarketingPerson, getCountryOption]);

  const onFetchWaybillNumber = async () => {
    const response = await fetchWaybillNumber();
    if (response) {
      setValue('generated_waybill_number', response);
      setValue('basicinfo.waybill_no', response.waybillnumber);
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      size='lg'
      style={{
        maxWidth: '1400px',
        width: '80%',
        minWidth: 400,
      }}
      backdrop={true}
    >
      {editData && (
        <ModalHeader style={{ background: '#e9e9ef' }}>
          <div>
            <h3>Order Number : {editData.waybillnumber}</h3>
          </div>
        </ModalHeader>
      )}
      <ModalBody
        style={{
          maxHeight: '85vh',
          overflowY: 'auto',
        }}
      >
        <Form onSubmit={handleSubmit(submitData)} className='order-modal'>
          <BasicInfoForm
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            getValues={getValues}
            editData={editData}
            watch={watch}
            selectedAddress={selectedAddress}
            onFetchWaybillNumber={onFetchWaybillNumber}
          />

          <div className='divider'></div>
          <AdressInputForm
            register={register}
            errors={errors}
            control={control}
            options={{ countries: countries }}
            editData={editData}
            addNew={false}
            setValue={setValue}
            types={['senderinfo', 'receiverinfo']}
            getValues={getValues}
            onSelectAddress={(address) => {
              setSelectedAddress(address);
            }}
            noCard={true}
          />
          <div className='divider'></div>
          <ItemInfoForm
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            editData={editData}
            getValues={getValues}
          />
          <div className='divider'></div>
          <div style={{ padding: 0, margin: 0 }}>
            <CostInfoForm
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              editData={editData}
              getValues={getValues}
              watch={watch}
              basicFreightError={basicFreightError}
            />
          </div>

          <EntryGrid title={t('waybill_entry.order_file_upload')}>
            <Row style={{ padding: 0, margin: 0 }}>
              <Col md='4'>
                <ImageUploadContainer
                  files={orderImageFiles}
                  setFiles={setOrderImageFiles}
                  showUpload={true}
                  networkFiles={editData?.order_file_names}
                  waybillnumber={editData?._id}
                  title={t('waybill_entry.packaging_list')}
                  type='order'
                />
              </Col>
            </Row>
          </EntryGrid>
          <div className='custom-modal-footer'>
            <Button color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button color='primary' type='submit'>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
