import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as InformationAction from 'store/actions/information';
import * as CurrencyAction from 'store/actions/currency';
import { Button } from 'reactstrap';
import List from './list';
import AddDialog from './addDialog';
import FilterBox from './filter';
import * as FinanceAction from 'store/actions/finance';
import { useHistory } from 'react-router';
import { getHeaders } from './headers';
import ChooseVoucherTypeDialog from './chooseVoucherTypeDialog';
import queryString from 'query-string';
import * as CollectionAction from 'store/actions/collection';
import { t } from 'i18next';
import DetailDialog from 'pages/waybill/query/detailDialog';
import useActionPermission from 'hooks/useActionPermission';
import { getWaybillByNumber } from 'services/waybill';
import useViewPermission from 'hooks/useViewPermission';

function Finance() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [voucherSelect, setVoucherSelect] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState(null);
  const [editData, setEditData] = React.useState(null);
  const [showDetail, setShowDetail] = React.useState(false);

  const actionLoading = useSelector((state) => state.loading.actionLoading);
  const { canPay } = useActionPermission('finance');
  const dispatch = useDispatch();
  const router = useHistory();
  const { isOwn, isBranch } = useViewPermission('finance');

  const waybill_finances = useSelector((state) => state.waybill.waybill_finances);

  React.useEffect(() => {
    dispatch(InformationAction.getBranch());
    dispatch(CollectionAction.getAll({}));
    dispatch(CurrencyAction.getAll({}));
  }, [dispatch, CollectionAction.getAll, InformationAction.getBranch]);

  React.useEffect(() => {
    loadData();
  }, [FinanceAction.getAll, router.location.search]);

  const loadData = (value = null) => {
    setSearchValue(value);
    let query = { limit: 10, page: 1 };
    if (router.location.search) query = queryString.parse(router.location.search);

    if (value) {
      query = { limit: 10, page: 1 };
    }

    if (isOwn()) query.isOwn = !!isOwn();
    if (isBranch()) query.isBranch = !!isBranch();

    dispatch(FinanceAction.getAll(query, value ? value : searchValue ? searchValue : {}));
  };

  return (
    <>
      <List
        headers={getHeaders()}
        //selectedData={selectedData}
        // onSelect={(e) => {
        //   if (selectedData.find((s) => s._id === e._id)) setSelectedData(selectedData.filter((s) => s._id !== e._id));
        //   else setSelectedData([...selectedData, e]);
        // }}
        onWaybillClick={async (e) => {
          const waybill = await getWaybillByNumber(e.waybillnumber);
          setEditData(waybill.data);
          setShowDetail(true);
        }}
        data={waybill_finances}
        title={t('sidebar.finance')}
        filter={
          <FilterBox
            onSearch={(value) => {
              setSelectedData([]);
              loadData(value);
            }}
          />
        }
        canPay={canPay}
        onPay={(eachRow) => {
          setSelectedData(eachRow);
          setModalOpen(true);
        }}
        onPrint={(eachRow) => {
          setSelectedData(eachRow);
          setVoucherSelect(true);
        }}
        buttons={
          <>
            {/* {canPay() && (
              <Button
                style={{ width: 100 }}
                size='sm'
                onClick={() => {
                  if (selectedData.length === 1) {
                    setModalOpen(true);
                  } else {
                    NotificationManager.error(t('finance.choose_one'));
                  }
                }}
              >
                <i className='fa fa-plus mr-2' />
                {t('finance.to_pay')}
              </Button>
            )} */}

            <Button
              style={{ width: 100 }}
              size='sm'
              disabled={actionLoading}
              onClick={() => {
                const query = {};
                if (isOwn()) query.isOwn = !!isOwn();
                if (isBranch()) query.isBranch = !!isBranch();
                dispatch(FinanceAction.exportData(searchValue ? { ...searchValue, ...query } : { ...query }));
              }}
            >
              {actionLoading ? <i className='fa fa-spinner mr-2' /> : <i className='fa fa-file-excel mr-2' />}
              {t('export')}
            </Button>
            {/* <Button
              style={{ width: 100 }}
              size='sm'
              onClick={() => {
                if (selectedData.length === 1) {
                  setVoucherSelect(true);
                } else {
                  NotificationManager.error(t('finance.choose_one_print'));
                }
              }}
            >
              <i className='fa fa-file-archive mr-2' />
              {t('print')}
            </Button> */}
            <Button
              style={{ width: 100 }}
              size='sm'
              onClick={() => {
                window.location.reload();
              }}
            >
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={t('finance.sale_invoice')}
        data={selectedData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values) => {
          setModalOpen(false);
          values.waybill_id = selectedData._id;
          dispatch(FinanceAction.pay(values));
          setSelectedData([]);
          //     setTimeout(() => loadData(), 1000);
        }}
      />
      {selectedData && (
        <ChooseVoucherTypeDialog
          modalOpen={voucherSelect}
          onToggle={() => setVoucherSelect(false)}
          selectedData={selectedData}
        />
      )}
      <DetailDialog
        data={editData}
        modalOpen={showDetail}
        onToggle={() => setShowDetail(false)}
        customItemName={editData?.item?.itemtype?.name}
        customPaymentMethod={editData?.cost.paymentType.name}
      />
    </>
  );
}

export default Finance;
