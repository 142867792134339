import React from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { t } from 'i18next';
import withEditWayBill from './useEditWayBill';
import SelectBox from 'components/SelectBox';
import { useDispatch, useSelector } from 'react-redux';
import { getAll } from 'store/actions/employee';
import { updateWayBill } from 'services/waybill';
const getSalePersonValue = (salePerson) => {
  return `${salePerson?._id},${salePerson?.name}`;
};
const getSalePersonObject = (salePerson) => {
  const person = salePerson?.split(',');
  return {
    _id: person[0],
    name: person[1],
  };
};
function EditWayBill({ toggle, data }) {
  const { control, handleSubmit, reset, setValue } = useForm();
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.information.employees);
  const salesPersonOptions = employees?.map((e) => {
    return {
      label: e.name,
      value: getSalePersonValue(e),
    };
  });

  React.useEffect(() => {
    dispatch(getAll());
  }, []);

  React.useEffect(() => {
    if (data) {
      setValue('sale_person', getSalePersonValue(data?.sale_person));
    } else {
      reset();
    }
  }, [data]);

  const submitData = async (values) => {
    const salePerson = getSalePersonObject(values?.sale_person);
    reset();
    await updateWayBill({
      ...values,
      _id: data?._id,
      sales_person: salePerson._id,
      sale_person: salePerson,
    });
    toggle();
  };

  return (
    <Form onSubmit={handleSubmit(submitData)} className='my-3 border-dark p-3 border'>
      <FormGroup className={classnames('mb-3')}>
        <label>{t('sale_person')}</label>
        <SelectBox
          control={control}
          name='sale_person'
          label={t('sale_person')}
          options={salesPersonOptions}
          value={getSalePersonValue(data?.sale_person)}
          rules={{ required: true }}
        />
      </FormGroup>
      <div className='text-center'>
        <Button className='my-4' color='primary' type='submit'>
          {t('submit')}
        </Button>
      </div>
    </Form>
  );
}

export default withEditWayBill(EditWayBill);
