import TextBox from 'components/Inputs/TextBox';
import React from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { t } from 'i18next';
import { updateWayBill } from 'services/waybill';
import withEditWayBill from './useEditWayBill';

function EditWayBill({ toggle, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  React.useEffect(() => {
    if (data) {
      setValue('cost.total', data?.cost.total);
    } else {
      reset();
    }
  }, [data]);

  const submitData = async (values) => {
    reset();
    data.cost.total = values.cost.total;
    await updateWayBill({
      ...{ cost: data.cost },
      _id: data?._id,
    });
    toggle();
  };

  return (
    <Form onSubmit={handleSubmit(submitData)} className='my-3 border-dark p-3 border'>
      <FormGroup className={classnames('mb-3')}>
        <label>{t('cost_total')}</label>
        <TextBox
          className='form-control'
          placeholder={t('cost_total')}
          type='number'
          registerProps={register('cost.total', {
            required: `${t('cost.total')} ${t('is_required')}`,
          })}
          errors={errors?.cost?.total}
          defaultValue={data?.cost?.total}
        />
      </FormGroup>
      <div className='text-center'>
        <Button className='my-4' color='primary' type='submit'>
          {t('submit')}
        </Button>
      </div>
    </Form>
  );
}

export default withEditWayBill(EditWayBill);
