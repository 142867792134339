export function getHeaders(started_way = 1) {
  if (started_way) {
    const headers = [
      // {
      //   text: '',
      //   width: 40,
      // },
      {
        text: 'cargo_loading.train_number',
        width: 40,
      },
      // { text: 'cargo_loading.waybill_number', width: 40 },
      {
        text: 'cargo_loading.date',
        width: 40,
      },
      {
        text: 'waybill_entry.depature_branch',
        width: 40,
      },
      {
        text: 'cargo_loading.destination',
        width: 40,
      },
      {
        text: 'cargo_loading.container',
        width: 40,
      },
      {
        text: 'operator',
        width: 40,
      },
      {
        text: 'actions',
        width: 40,
      },
    ];

    return headers;
  }

  const headers = [
    // {
    //   text: '',
    //   width: 40,
    // },

    {
      text: 'cargo_loading.train_number',
      width: 40,
    },
    // { text: 'cargo_loading.waybill_number', width: 40 },
    {
      text: 'cargo_loading.date',
      width: 40,
    },
    {
      text: 'cargo_loading.destination',
      width: 40,
    },
    {
      text: 'cargo_loading.container',
      width: 40,
    },
    {
      text: 'operator',
      width: 40,
    },
    {
      text: 'actions',
      width: 40,
    },
  ];

  return headers;
}
