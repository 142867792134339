import React from 'react';
import { Button } from 'reactstrap';
import ExportOrderForm from './exportOrderForm';
import { PDFDownloadLink } from '@react-pdf/renderer';

function OrderFormExport({ selectedData }) {
  const exportRef = React.useRef();
  const [showPDF_Download, setShowPDF_Download] = React.useState(false);
  return (
    <>
      {!showPDF_Download ? (
        <Button
          style={{ width: 120, height: 90, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          size='sm'
          onClick={() => {
            setShowPDF_Download(true);
            setTimeout(() => {
              if (exportRef?.current) {
                exportRef.current.click();
              }
            }, 1000);

            setTimeout(() => {
              setShowPDF_Download(false);
            }, 1000);
          }}
        >
          <i className='fa fa-file-pdf mr-2' style={{ fontSize: '80px' }} />
        </Button>
      ) : (
        <PDFDownloadLink
          document={<ExportOrderForm data={selectedData} />}
          fileName={`OrderForm-${selectedData.waybillnumber}.pdf`}
        >
          {({ loading, error }) => {
            if (error) return 'error';

            if (!error && loading) return '...';

            if (!error && !loading)
              return (
                <button
                  type='button'
                  style={{ width: 120, height: 90, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  size='sm'
                  ref={exportRef}
                >
                  <i className='fa fa-file-pdf mr-2' style={{ fontSize: '80px' }} />
                </button>
              );
          }}
        </PDFDownloadLink>
      )}
    </>
  );
}
export default OrderFormExport;
