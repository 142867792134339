import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, FormGroup, Card, CardBody, CardHeader } from 'reactstrap';
import * as PaymentTypeAction from 'store/actions/payment_type';
import SelectBox from 'components/SelectBox';
import TextBox from 'components/Inputs/TextBox';
import { t } from 'i18next';
import NumberInput from 'components/Inputs/NumberInput';
import { customRound } from 'utli';
function CostInfoForm({ errors, control, setValue, getValues, watch }) {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(PaymentTypeAction.getAll({}));
  }, [PaymentTypeAction.getAll, dispatch]);

  const [total, setTotal] = React.useState(0);

  const currencies = useSelector((state) => state.other_maintenance.currencies);

  const payment_types = useSelector((state) => state.other_maintenance.payment_types);

  const paymentTypeOptions = payment_types?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  const currencyOptions = currencies?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  React.useEffect(() => {
    const subscription = watch((value) => {
      getTotal(value.costinfo);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const getTotal = (costinfo) => {
    if (costinfo) {
      let {
        prepaid_for_client,
        pickup_fee,
        clearence_fee,
        storage_fee,
        loading_fee,
        packaging_fee,
        agent_fee,
        basic_freight,
        insurance_fee,
      } = costinfo;

      let sum = 0;
      if (insurance_fee !== undefined) {
        if (insurance_fee > 0) sum += parseFloat(insurance_fee);
      }
      if (prepaid_for_client !== undefined) {
        if (prepaid_for_client > 0) sum += parseFloat(prepaid_for_client);
      }
      if (pickup_fee !== undefined) {
        if (pickup_fee > 0) sum += parseFloat(pickup_fee);
      }
      if (clearence_fee !== undefined) {
        if (clearence_fee > 0) sum += parseFloat(clearence_fee);
      }
      if (storage_fee !== undefined) {
        if (storage_fee > 0) sum += parseFloat(storage_fee);
      }
      if (loading_fee !== undefined) {
        if (loading_fee > 0) sum += parseFloat(loading_fee);
      }
      if (packaging_fee !== undefined) {
        if (packaging_fee > 0) sum += parseFloat(packaging_fee);
      }
      if (agent_fee !== undefined) {
        if (agent_fee > 0) sum += parseFloat(agent_fee);
      }
      if (basic_freight !== undefined) {
        if (basic_freight > 0) sum += parseFloat(basic_freight);
      }

      setTotal(customRound(sum.toFixed(1)));
    }
  };

  React.useEffect(() => {
    getTotal(getValues('costinfo'));
  }, []);

  return (
    <Card className='entry-grid'>
      <CardHeader>{t('waybill_entry.cost_information')}</CardHeader>
      <CardBody style={{ paddingRight: 0 }}>
        <Row className='pt-1 pb-1'>
          <Col md={4}>
            <Row>
              <Col md={10} className='m-0 p-0'>
                <FormGroup>
                  <label style={{ fontSize: '1vw' }}>{t('waybill_entry.insurance_fee')}</label>
                  <NumberInput
                    onChange={(value) => {
                      setValue('costinfo.insurance_fee', value);
                    }}
                    className='form-control mr-2'
                    value={getValues('costinfo.insurance_fee')}
                    errors={errors?.costinfo?.insurance_fee}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={10} className='m-0 p-0 mt-2'>
                <FormGroup>
                  {t('waybill_entry.prepaid_for_client')}
                  <NumberInput
                    className='form-control mr-2'
                    onChange={(value) => {
                      setValue('costinfo.prepaid_for_client', value);
                    }}
                    value={getValues('costinfo.prepaid_for_client')}
                    errors={errors?.costinfo?.prepaid_for_client}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={10} className='m-0 p-0'>
                <FormGroup>
                  <label style={{ fontSize: '1vw' }}>{t('waybill_entry.pickup_fee')}</label>
                  <NumberInput
                    className='form-control mr-2'
                    onChange={(value) => {
                      setValue('costinfo.pickup_fee', value);
                    }}
                    value={getValues('costinfo.pickup_fee')}
                    errors={errors?.costinfo?.pickup_fee}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={10} className='m-0 p-0'>
                <FormGroup>
                  <label>{t('waybill_entry.clearance_fee')}</label>
                  <NumberInput
                    onChange={(value) => {
                      setValue('costinfo.clearence_fee', value);
                    }}
                    className='form-control mr-2'
                    value={getValues('costinfo.clearence_fee')}
                    errors={errors?.costinfo?.clearence_fee}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={10} className='m-0 p-0'>
                <FormGroup>
                  <label> {t('waybill_entry.storage_fee')}</label>
                  <NumberInput
                    className='form-control mr-2'
                    onChange={(value) => {
                      setValue('costinfo.storage_fee', value);
                    }}
                    value={getValues('costinfo.storage_fee')}
                    errors={errors?.costinfo?.storage_fee}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={10} className='m-0 p-0'>
                <FormGroup>
                  <label style={{ fontSize: '1vw' }}>{t('waybill_entry.loading_fee')}</label>
                  <NumberInput
                    onChange={(value) => {
                      setValue('costinfo.loading_fee', value);
                    }}
                    className='form-control mr-2'
                    value={getValues('costinfo.loading_fee')}
                    errors={errors?.costinfo?.loading_fee}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={10} className='m-0 p-0'>
                <FormGroup>
                  <label> {t('waybill_entry.packaging_fee')}</label>
                  <NumberInput
                    onChange={(value) => {
                      setValue('costinfo.packaging_fee', value);
                    }}
                    className='form-control mr-2'
                    value={getValues('costinfo.packaging_fee')}
                    errors={errors?.costinfo?.packaging_fee}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={10} className='m-0 p-0'>
                <FormGroup>
                  <label> {t('waybill_entry.agent_fee')}</label>
                  <NumberInput
                    onChange={(value) => {
                      setValue('costinfo.agent_fee', value);
                    }}
                    className='form-control mr-2'
                    value={getValues('costinfo.agent_fee')}
                    errors={errors?.costinfo?.agent_fee}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={4} className='m-0 p-0'>
                <FormGroup>
                  <label style={{ fontSize: '1vw' }}>{t('waybill_entry.basic_freight')}</label>
                  <NumberInput
                    className='form-control'
                    value={getValues('costinfo.org_basic_freight')}
                    error={t('waybill_entry.basic_freight') + ' is required'}
                    onChange={(value) => {
                      setValue('costinfo.org_basic_freight', value);
                      const total = Number(value * Number(getValues('costinfo.qty_basic_freight') || 1));

                      if (total <= 100) {
                        setValue('costinfo.basic_freight', 100);
                      } else {
                        setValue('costinfo.basic_freight', total);
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={3} className='m-0 p-0'>
                <FormGroup>
                  <label>{t('qty')}</label>
                  <TextBox
                    className='form-control mr-2'
                    type='number'
                    step='.001'
                    value={watch('costinfo.qty_basic_freight')}
                    errors={errors?.costinfo?.qty_basic_freight}
                    defaultValue={1}
                    outputPrepend={watch('costinfo.qty_basic_freight_label')}
                    onChange={(e) => {
                      setValue('costinfo.qty_basic_freight', e.target.value);
                      const total = Number(e.target.value * Number(getValues('costinfo.org_basic_freight')));

                      if (total <= 100) {
                        setValue('costinfo.basic_freight', 100);
                      } else {
                        setValue('costinfo.basic_freight', total);
                      }
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={4} className='m-0 p-0'>
                <FormGroup>
                  <label>{t('amount')}</label>
                  <NumberInput
                    readOnly
                    className='form-control'
                    disabled={true}
                    value={
                      !isNaN(watch('costinfo.basic_freight'))
                        ? customRound(watch('costinfo.basic_freight').toFixed(1))
                        : ''
                    }
                    errors={errors?.costinfo?.basic_freight}
                  />
                </FormGroup>
              </Col>
              {watch('costinfo.org_basic_freight') * (watch('costinfo.qty_basic_freight') || 1) < 100 && (
                <Col md={10} className='m-0 p-0'>
                  <span
                    style={{
                      fontSize: 14,
                      padding: 0,
                      margin: 0,
                      color: '#838383',
                    }}
                  >
                    Note : Amount will be calculated as 100 because the actual amount is less than 100.
                  </span>
                </Col>
              )}
            </Row>
          </Col>
          <Col md={4} className='ml-0 pl-0' style={{ paddingRight: '35px' }}>
            <FormGroup>
              <label>{t('waybill_entry.total')}</label>

              <NumberInput readOnly className='form-control' disabled={true} value={total} />
            </FormGroup>
          </Col>
          <Col md={4} className='ml-0 pl-0  pr-4' style={{ paddingRight: '35px' }}>
            <FormGroup>
              <label>{t('waybill_entry.paymentType')}</label>
              <SelectBox
                label={t('waybill_entry.paymentType')}
                control={control}
                name='costinfo.paymentType'
                options={paymentTypeOptions}
                rules={{ required: true }}
              />
            </FormGroup>
          </Col>
          <Col md={4} className='ml-0 pl-0  pr-4' style={{ paddingRight: '35px' }}>
            <FormGroup>
              <label>Currency</label>
              <SelectBox
                label='Currency'
                control={control}
                name='costinfo.currency'
                options={currencyOptions}
                rules={{ required: true }}
              />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
export default CostInfoForm;
