import { Container, Row } from 'reactstrap';
import Transaction from './transaction';
import WayBill from './waybill';
import Order from './order';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

function QAPage() {
  const router = useHistory();
  useEffect(() => {
    const checkPassword = () => {
      const correctPassword = '123456';
      let enteredPassword;

      while (enteredPassword !== correctPassword) {
        enteredPassword = prompt('Enter the password:');
        if (enteredPassword === correctPassword) {
          break;
        } else {
          const isConfirmed = window.confirm(
            'Incorrect password! Actions on the page are not allowed. Click OK to try again.'
          );
          if (!isConfirmed) {
            router.push('/');
            break;
          }
        }
      }
    };

    checkPassword();
  }, []);
  return (
    <div>
      <h1 className='text-center mt-3'>EASYF DASHBOARD QA</h1>
      <Container>
        <Row>
          <Transaction />
          <WayBill />
          <Order />
        </Row>
      </Container>
    </div>
  );
}
export default QAPage;
