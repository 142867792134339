import TextBox from 'components/Inputs/TextBox';
import React from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { t } from 'i18next';
import { updateOrder } from 'services/order';
import withEditOrder from './useEditOrder';

function EditOrder({ toggle, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  React.useEffect(() => {
    if (data) {
      setValue('address_code', data?.address_code);
    } else {
      reset();
    }
  }, [data]);

  const submitData = async (values) => {
    reset();
    await updateOrder({
      ...values,
      _id: data?._id,
    });
    toggle();
  };

  return (
    <Form onSubmit={handleSubmit(submitData)} className='my-3 border-dark p-3 border'>
      <FormGroup className={classnames('mb-3')}>
        <label>{t('address_code')}</label>
        <TextBox
          className='form-control'
          placeholder={t('address_code')}
          type='text'
          registerProps={register('address_code', {
            required: `${t('address_code')} ${t('is_required')}`,
          })}
          errors={errors.address_code}
          defaultValue={data?.address_code}
        />
      </FormGroup>
      <div className='text-center'>
        <Button className='my-4' color='primary' type='submit'>
          {t('submit')}
        </Button>
      </div>
    </Form>
  );
}

export default withEditOrder(EditOrder);
