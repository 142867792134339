import EditWayBillSalePerson from './EditWayBillSalePerson';
import EditWayBillAddressCode from './EditWayBillAddressCode';
import EditWayBillTotal from './EditWayBillTotal';
import { Col } from 'reactstrap';

const EDIT_WAYBILL_ADDRESS_CODE = 'Update Waybill Address Code';
const EDIT_WAYBILL_COST_TOTAL = 'Update Waybill Cost Total';
const EDIT_WAYBILL_SALE_PERSON = 'Update Waybill Sale Person';

export default function WayBill() {
  return (
    <>
      <Col md={4}>
        <EditWayBillAddressCode title={EDIT_WAYBILL_ADDRESS_CODE} />
      </Col>
      <Col md={4}>
        <EditWayBillTotal title={EDIT_WAYBILL_COST_TOTAL} />
      </Col>
      <Col md={4}>
        <EditWayBillSalePerson title={EDIT_WAYBILL_SALE_PERSON} />
      </Col>
    </>
  );
}
