import React from 'react';
import { Button } from 'reactstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ShippingMarkerPrint from './print_component';

function ShippingMaker({ selectedData }) {
  const onPrintClick = () => {
    setTimeout(() => {
      const input = document.getElementById('pdf_print');
      if (!input) return;
      input.style.display = 'block';
      html2canvas(input, { scale: 1.3 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF({
          orientation: 'landscape',
          compress: false,
          unit: 'px',
          format: 'a5',
          floatPrecision: 'smart',
          precision: 1,
        });
        pdf.addImage(imgData, 'JPEG', 10, 20);
        pdf.save(`ShippingMarker-${selectedData?.waybillnumber}.pdf`);
      });
      input.style.display = 'none';
    }, 200);
  };
  return (
    <>
      <Button
        style={{ width: 120, height: 90, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        size='sm'
        onClick={() => {
          onPrintClick();
        }}
      >
        <i className='fa fa-file-archive mr-2' style={{ fontSize: '80px' }} />
      </Button>
      {selectedData && <ShippingMarkerPrint data={selectedData} />}
    </>
  );
}

export default ShippingMaker;
