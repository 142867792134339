export function getHeaders(selectDialog = false) {
  const headers = [
    // {
    //   text: '',
    //   width: 50,
    //   className: 'fixed-cell left',
    // },

    {
      text: 'waybill_entry.order_number',
      className: 'fixed-cell left',
      // left: 55,
    },
    {
      text: 'waybill_entry.order_date',
    },
    {
      text: 'waybill_entry.shipment_type',
    },
    {
      text: 'waybill_entry.shipping_mode',
    },
    {
      text: 'waybill_entry.delivery_mode',
    },
    {
      text: 'sidebar.custom_clearence',
    },
    {
      text: 'waybill_entry.delivery_place',
    },
    {
      text: 'waybill_entry.destination',
    },

    {
      text: 'address_book.code',
    },
    {
      text: 'waybill_entry.sender_company',
    },
    {
      text: 'address_book.sender',
    },
    {
      text: 'address_book.phonenumber',
    },
    {
      text: 'waybill_entry.receiver_company',
    },
    {
      text: 'address_book.receiver',
    },
    {
      text: 'address_book.phonenumber',
    },
    {
      text: 'waybill_entry.cargo_type',
    },
    {
      text: 'waybill_entry.item_type',
    },
    {
      text: 'waybill_entry.item_name',
    },
    {
      text: 'waybill_entry.qty',
    },
    {
      text: 'waybill_entry.weight',
    },
    {
      text: 'waybill_entry.volume',
    },
    {
      text: 'waybill_entry.value',
    },
    {
      text: 'waybill_entry.remark',
    },
    {
      text: 'waybill_entry.paymentCurrency',
    },
    {
      text: 'waybill_entry.total',
    },

    {
      text: 'waybill_entry.sale_person',
    },
    {
      text: 'waybill_entry.cargo_date',
    },
    {
      text: 'waybill_entry.status',
    },
    {
      text: 'actions',
      className: 'fixed-cell',
      width: 400,
    },
  ];

  if (selectDialog) {
    headers.push({
      text: 'actions',
      className: 'fixed-cell',
      width: 80,
    });
  }

  return headers;
}
