import React, { useState, useRef } from 'react';
import { Button, Modal, ModalBody, Card, CardBody } from 'reactstrap';
import { getOrderByWayBillNumber } from 'services/order';

const withEditOrder = (WrappedComponent) => {
  return function EditOrderWrapper({ title }) {
    const [isUpdateOrderOpen, setIsUpdateOrderOpen] = useState(false);
    const searchRef = useRef(null);
    const [editData, setEditData] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [clickedSearchBtn, setClickedSearchBtn] = useState(false);

    const toggleUpdateOrder = () => {
      setIsUpdateOrderOpen((prev) => !prev);
    };

    const searchOrder = async (waybillnumber) => {
      if (!waybillnumber) return;
      setIsSearching(true);
      setClickedSearchBtn(true);
      const result = await getOrderByWayBillNumber({ waybillnumber: waybillnumber });
      setEditData(result);
      setIsSearching(false);
    };

    const reset = () => {
      setEditData(null);
      toggleUpdateOrder();
      setClickedSearchBtn(false);
    };

    const onClear = () => {
      setEditData(null);
      setClickedSearchBtn(false);
    };

    return (
      <>
        <Card onClick={toggleUpdateOrder} color='primary' style={{ cursor: 'pointer' }}>
          <CardBody className='text-white'>{title}</CardBody>
        </Card>
        {isUpdateOrderOpen && (
          <Modal isOpen={isUpdateOrderOpen} style={{ top: '5%' }} backdrop={true} size='lg'>
            <div className=' modal-header'>
              <h5 className=' modal-title'>{title}</h5>
              <button aria-label='Close' className=' close' type='button' onClick={reset}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <div className='d-flex'>
                <input ref={searchRef} className='form-control' />
                <Button onClick={() => searchOrder(searchRef.current.value)} color='danger'>
                  Search
                </Button>
                {editData && <Button onClick={onClear}>Clear</Button>}
              </div>
              {isSearching ? (
                <h3 className='m-3'>Searching...</h3>
              ) : (
                clickedSearchBtn && (
                  <>
                    {!editData ? (
                      <h3 className='m-3 text-danger'>No result found!</h3>
                    ) : (
                      <WrappedComponent toggle={reset} data={editData} />
                    )}
                  </>
                )
              )}
            </ModalBody>
          </Modal>
        )}
      </>
    );
  };
};

export default withEditOrder;
