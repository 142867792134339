import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import dayjs from 'dayjs';
import TextBox from 'components/Inputs/TextBox';
import DateRangePicker from 'components/Inputs/DateRangePicker';
import { t } from 'i18next';

function FilterBox({ onSearch, isOwn }) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  const branches = useSelector((state) => state.information.branches);
  let branchOptions = [];
  if (branches.map) {
    branchOptions = branches?.map((e) => {
      return {
        label: e.label,
        value: e._id,
      };
    });
  }

  const marketing_persons = useSelector((state) => state.information.marketing_persons);
  const marketingPersonOptions = marketing_persons?.map((e) => {
    return {
      label: e.label,
      value: e._id,
    };
  });

  const [searchType, setSearchType] = React.useState('waybillnumber');
  const [search, setSearch] = React.useState();
  const [dateSearch, setDateSearch] = React.useState();
  return (
    <>
      <Row>
        <Col className='mb-1' md='5' sm='6'>
          {(searchType === 'destination' || searchType === 'departure') && (
            <Select
              styles={customStyles}
              options={branchOptions}
              onChange={(value) => setSearch(value.value)}
              value={branchOptions.find((b) => b._id === search)}
            />
          )}

          {['customer_code', 'waybillnumber', 'phonenumber'].includes(searchType) && (
            <TextBox
              onChange={(e) => setSearch(e.target.value)}
              style={{ height: 34 }}
              value={typeof search === 'object' ? '' : search}
              placeholder={`Search by ${searchType}`}
            />
          )}
          {searchType === 'marketing_person' && (
            <Select
              isDisabled={isOwn === true}
              styles={customStyles}
              options={marketingPersonOptions}
              onChange={(value) => setSearch(value.value)}
              value={marketingPersonOptions.find((b) => b._id === search)}
            />
          )}
        </Col>
        <Col className='mb-1' md='2' sm='6'>
          <Select
            styles={customStyles}
            defaultValue={{
              value: 'waybillnumber',
              label: t('waybill_entry.way_bill_no'),
            }}
            onChange={(val) => {
              setSearch('');
              setSearchType(val.value);
            }}
            options={[
              {
                value: 'waybillnumber',
                label: t('waybill_entry.way_bill_no'),
              },
              {
                value: 'departure',
                label: t('waybill_entry.depature_branch'),
              },
              {
                value: 'destination',
                label: t('waybill_entry.destination'),
              },
              {
                value: 'marketing_person',
                label: t('address_book.sale_person'),
              },
              { value: 'customer_code', label: t('address_book.code') },
              { value: 'phonenumber', label: t('address_book.phonenumber') },
            ]}
          />
        </Col>

        <Col className='mb-1' md='4' sm='6' xs='10'>
          <DateRangePicker
            onDateChange={(dates) => {
              setDateSearch({
                startDate: dates[0],
                endDate: dates[1],
              });
            }}
          />
        </Col>

        {searchType && (
          <Button
            size='sm'
            color='success'
            style={{ height: 35 }}
            onClick={() => {
              if (search || dateSearch) {
                //  setSearch('')
                const searchData = {};
                if (search) searchData[searchType] = search.trim();
                if (dateSearch?.startDate) {
                  searchData.startDate = dayjs(dateSearch.startDate).format('MM/DD/YYYY');

                  searchData.endDate =
                    dayjs(dateSearch.endDate).format('MM/DD/YYYY') || dayjs(new Date()).format('MM/DD/YYYY');
                }
                onSearch(searchData);
              }
            }}
          >
            <i className='fa fa-search' />
          </Button>
        )}
      </Row>
    </>
  );
}

export default FilterBox;
