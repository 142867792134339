import React from 'react';
import { Button, Table as ReactStrapTable } from 'reactstrap';
import { t } from 'i18next';
import ReactToPrint from 'react-to-print';
import { moneyFormatter } from 'utli';
import dayjs from 'dayjs';
function PrintPdfButton({ wayInfo, data, documentTitle, started_way }) {
  const pdfRef = React.useRef();
  const headers = [
    'NO',
    'WAYBILL',
    'CUSTOMER CODE',
    'RECEIVER NAME',
    'CARGO NAME',
    'TOTAL QTY',
    'GROSS WEIGHT',
    'MEASUREMENT',
    'DESTINATION BRANCH',
    'REMARK',
  ];

  return (
    <>
      <ReactToPrint
        documentTitle={documentTitle}
        trigger={() => {
          return (
            <Button style={{ width: 100, height: 40 }} size='sm' onClick={() => {}}>
              <i className='fa fa-file-pdf mr-3' />
              {t('print')}
            </Button>
          );
        }}
        content={() => {
          return pdfRef.current;
        }}
        pageStyle={`@media print {
          table { width: 100%; max-width: 100%; font-size: 80%; }
        }`}
      />

      <div style={{ display: 'none' }}>
        <div style={{ width: '100%', padding: '20px' }} ref={pdfRef}>
          <div>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  marginBottom: '5px',
                  fontSize: 16,
                }}
              >
                <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.train_number')} :</div>{' '}
                {wayInfo?.way_number}
              </div>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  marginBottom: '5px',
                  fontSize: 16,
                }}
              >
                <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.date')} : </div>
                {dayjs(wayInfo?.date).format('DD / MM / YYYY')}
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              {started_way && (
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    marginBottom: '5px',
                    fontSize: 16,
                  }}
                >
                  <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.departure_branch')} : </div>
                  {wayInfo?.departure?.name}
                </div>
              )}
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  marginBottom: '5px',
                  fontSize: 16,
                }}
              >
                <div style={{ width: '50%', fontWeight: 'bold', fontSize: 14 }}>
                  {t('cargo_loading.destination_branch')} :{' '}
                </div>
                {wayInfo?.destination?.name}
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  marginBottom: '5px',
                  fontSize: 16,
                }}
              >
                <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.container')} :</div>
                {wayInfo?.container}
              </div>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  marginBottom: '5px',
                  fontSize: 16,
                }}
              >
                <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.driver')} :</div>
                {wayInfo?.driver}
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  marginBottom: '5px',
                  fontSize: 16,
                }}
              >
                <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.phone_number')} :</div>{' '}
                {wayInfo?.phonenumber}
              </div>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  marginBottom: '5px',
                  fontSize: 16,
                }}
              >
                <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.driver_fee')}: </div>
                {moneyFormatter(wayInfo?.driver_fee)}
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  marginBottom: '5px',
                  fontSize: 16,
                }}
              >
                <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.note')} :</div> {wayInfo?.note}
              </div>
            </div>
          </div>
          <ReactStrapTable style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
            <thead>
              <tr
                style={{
                  border: '1px solid black',
                }}
              >
                {headers.map((e, index) => (
                  <td
                    style={{
                      border: '1px solid black',
                      fontWeight: 'bolder',
                      whiteSpace: index === 8 || index === 5 || index === 2 ? 'pre-line' : '',
                    }}
                    key={`thead-${Math.random()}`}
                  >
                    {e}
                  </td>
                ))}
              </tr>
            </thead>

            <tbody style={{ fontWeight: 'bolder' }}>
              {data?.docs?.map((e, index) => (
                <tr
                  key={`table-row-${index}-${Math.random()}`}
                  style={{ border: '1px solid black', borderCollapse: 'collapse' }}
                >
                  <td style={{ width: '30px', border: '1px solid black' }}>{index + 1}</td>
                  <td style={{ width: '28px', border: '1px solid black' }}>{e.waybillnumber}</td>
                  <td style={{ width: '28px', border: '1px solid black' }}>{e.waybill?.address_code}</td>
                  <td style={{ width: '28px', border: '1px solid black' }}>{e.waybill?.receiver?.name}</td>
                  <td style={{ width: '30px', border: '1px solid black' }}>{e.waybill?.item?.itemname}</td>
                  <td style={{ width: '10px', border: '1px solid black' }}>{e.qty}</td>
                  <td style={{ width: '10px', border: '1px solid black' }}>{e.weight}</td>
                  <td style={{ width: '10px', border: '1px solid black' }}>{e.volume}</td>
                  <td style={{ width: '26px', border: '1px solid black' }}>{e.destination}</td>
                  <td style={{ width: '70px', border: '1px solid black', whiteSpace: 'pre-line' }}>
                    {e.waybill?.remark}
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot style={{ fontWeight: 'bolder' }}>
              <tr>
                <td>-</td>
                <td>{t('waybill_entry.total')}</td>
                <td colSpan={3}></td>
                <td>{data?.totalSum?.qty?.toFixed(2)}</td>
                <td>{data?.totalSum?.weight?.toFixed(2)}</td>
                <td>{data?.totalSum?.volume?.toFixed(2)}</td>
              </tr>
            </tfoot>
          </ReactStrapTable>
        </div>
      </div>
    </>
  );
}

export default PrintPdfButton;
