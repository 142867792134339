import React from 'react';
import { moneyDecimalFormatter, isExpired, customRound } from 'utli';
import Table from 'components/Table';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { t } from 'i18next';
import PrintTranscation from './printTranscation.js';

function List({
  headers,
  //selectedData = [],
  data,
  onChoose,
  //onSelect,
  // title,
  buttons,
  filter,
  // showDetail,
  onPageChange,
  // onChoose,
  onWaybillClick,
}) {
  return (
    <div className='address-table'>
      <Table
        data={data}
        title={t('sidebar.finance')}
        subtitle={t('sidebar.transaction')}
        filter={filter}
        onPageChange={onPageChange}
        headers={headers.map((e) => (
          <th
            className={clsx('headers', e.className)}
            key={`thead-${Math.random()}`}
            style={{ width: e.width, left: e.left }}
          >
            {t(e.text)}
          </th>
        ))}
        buttons={buttons}
        rows={data?.docs?.map((e, index) => (
          <>
            {e.isNewFormat ? (
              <tr key={`table-row-${index}-${Math.random()}`} className={clsx(isExpired(e.expire_date) && 'deactive')}>
                <td className='fixed-cell left'>
                  <span style={{ cursor: 'pointer' }} onClick={() => onWaybillClick(e)}>
                    {e.waybill_number}
                  </span>
                </td>

                <td>{e.transaction_waybill?.address_code}</td>

                <td>{dayjs(e?.purchase_date).format('DD/MM/YYYY')}</td>
                <td>{dayjs(e?.transaction_waybill?.shipping_date).format('DD/MM/YYYY')}</td>
                <td>{e.paymentType?.name}</td>
                <td>{e.collectionType?.name}</td>
                <td>{e.currency}</td>
                <td>{moneyDecimalFormatter(customRound(e.paid_amount))}</td>
                <td>{e.exchangeRate}</td>
                <td>{moneyDecimalFormatter(customRound(e.receiveAmount))}</td>
                <td>{moneyDecimalFormatter(customRound(e.billing_amount))}</td>
                <td>{moneyDecimalFormatter(customRound(e.discount))}</td>
                <td>{moneyDecimalFormatter(customRound(e.remaining_balance))}</td>
                <td>{e.reference_number}</td>
                <td>{e.transaction_waybill?.createdBranch?.name}</td>
                <td>{e.transaction_waybill?.destination?.name}</td>
                <td>{e.transaction_waybill?.sale_person?._id?.name}</td>
                <td>{e.createdBy?.name}</td>
                <td>{e.remark}</td>
                {!onChoose && (
                  <td className='fixed-cell px-2'>
                    <PrintTranscation selectedData={e} />
                  </td>
                )}
              </tr>
            ) : (
              <tr key={`table-row-${index}-${Math.random()}`} className={clsx(isExpired(e.expire_date) && 'deactive')}>
                <td className='fixed-cell left'>
                  <span style={{ cursor: 'pointer' }} onClick={() => onWaybillClick(e)}>
                    {e.waybill_number}
                  </span>
                </td>

                <td>{e.transaction_waybill?.address_code}</td>

                <td>{dayjs(e?.purchase_date).format('DD/MM/YYYY')}</td>
                <td>{dayjs(e?.transaction_waybill?.shipping_date).format('DD/MM/YYYY')}</td>
                <td>{e.paymentType?.name}</td>
                <td>{e.collectionType?.name}</td>
                <td>{e.currency}</td>
                <td>{moneyDecimalFormatter(customRound(e.paid_amount))}</td>
                <td>{e.exchangeRate}</td>
                <td>{moneyDecimalFormatter(customRound(e.paid_in_currency))}</td>
                <td>{moneyDecimalFormatter(customRound(e.billing_amount))}</td>
                <td>{moneyDecimalFormatter(customRound(e.discount))}</td>
                <td>
                  {moneyDecimalFormatter(
                    customRound(e.billing_amount) - customRound(e.paid_amount) - customRound(e.discount)
                  )}
                </td>
                <td>{e.reference_number}</td>
                <td>{e.transaction_waybill?.createdBranch?.name}</td>
                <td>{e.transaction_waybill?.destination?.name}</td>
                <td>{e.transaction_waybill?.sale_person?._id?.name}</td>
                <td>{e.createdBy?.name}</td>
                <td>{e.remark}</td>
                {!onChoose && (
                  <td className='fixed-cell px-2'>
                    <PrintTranscation selectedData={e} />
                  </td>
                )}
              </tr>
            )}
          </>
        ))}
      />
    </div>
  );
}

export default List;
