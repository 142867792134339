import { useRef, useState } from 'react';
import { Button, Card, CardBody, Modal, ModalBody } from 'reactstrap';
import EditTransaction from './EditTransaction';
import { getTranscations } from 'services/transcation';

function UpdateTransaction() {
  const [isUpdateTransactionOpen, setIsUpdateTransactionOpen] = useState(false);
  const searchRef = useRef(null);
  const [editData, setEditData] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const toggleUpdateTransaction = () => {
    setIsUpdateTransactionOpen((prev) => !prev);
  };

  const searchTransaction = async () => {
    if (!searchRef.current.value) return;
    setIsSearching(true);
    const result = await getTranscations({ waybillnumber: searchRef.current.value });
    if (result) setEditData(result);
    setIsSearching(false);
  };

  const reset = () => {
    setEditData(null);
    toggleUpdateTransaction();
  };

  return (
    <>
      <Card onClick={toggleUpdateTransaction} color='primary' style={{ cursor: 'pointer' }}>
        <CardBody className='text-white'>Update Transaction</CardBody>
      </Card>
      {isUpdateTransactionOpen && (
        <Modal isOpen={isUpdateTransactionOpen} style={{ top: '5%' }} backdrop={true} size='lg'>
          <div className=' modal-header'>
            <h5 className=' modal-title'>Update Transaction</h5>
            <button aria-label='Close' className=' close' type='button' onClick={reset}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <div className='d-flex'>
              <input ref={searchRef} className='form-control' />
              <Button onClick={searchTransaction} color='danger'>
                Search
              </Button>
              {editData && <Button onClick={() => setEditData(null)}>Clear</Button>}
            </div>
            {isSearching ? (
              <h3 className='m-3'>Searching...</h3>
            ) : (
              <>
                {editData?.length === 0 ? (
                  <h3 className='m-3 text-danger'>No result found!</h3>
                ) : (
                  editData?.map((data) => <EditTransaction key={data?._id} toggle={reset} data={data} />)
                )}
              </>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
}
export default UpdateTransaction;
