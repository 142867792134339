import { Col } from 'reactstrap';
import UpdateTransaction from './UpdateTransaction';

export default function index() {
  return (
    <Col md={4}>
      <UpdateTransaction />
    </Col>
  );
}
