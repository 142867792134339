import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

import './style.scss';

function PrintDialog({ modalOpen, onToggle, children }) {
  return (
    <Modal isOpen={modalOpen} size='lg' backdrop={true} style={{ top: '10%' }}>
      <div className=' modal-header' style={{ background: '#e9e9ef' }}>
        <h5 className=' modal-title'>Print Dialog</h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <ModalBody className='print-detail-dialog'>{children}</ModalBody>
    </Modal>
  );
}
export default PrintDialog;
