import React from 'react';
import { Button } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import PrintWayBillLabelComponent from './print_waybill_label_component';
// import { t } from 'i18next';
function WaybillLabelExport({ selectedData }) {
  const labelRef = React.useRef();
  // @page {
  //   size: 100mm 75mm!important;
  //   margin: 0px!important;
  //   padding: 0px!important;

  // }

  return (
    <>
      <ReactToPrint
        trigger={() => {
          return (
            <Button
              style={{ width: 120, height: 90, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              size='sm'
            >
              <i className='fa fa-file-excel mr-2' style={{ fontSize: '80px' }} />
              {/* {t('waybill_entry.label')} */}
            </Button>
          );
        }}
        content={() => {
          return labelRef.current;
        }}
      />

      <div style={{ display: 'none' }}>
        <PrintWayBillLabelComponent ref={labelRef} data={selectedData} />
      </div>
    </>
  );
}

export default WaybillLabelExport;
