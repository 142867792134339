import React from 'react';
import Table from 'components/Table';
import clsx from 'clsx';
import dayjs from 'dayjs';
// import CheckBox from 'components/Inputs/CheckBox';
import { moneyFormatter, getCargoType, moneyDecimalFormatter, customRound } from 'utli';
import { t } from 'i18next';
import { Button } from 'reactstrap';

function List({
  headers,
  /*selectedData*/
  data,
  onToggle,
  /*onSelect*/
  saleReport,
  buttons,
  filter,
  showDetail,
  onEdit,
  onDelete,
  canEdit,
  canDelete,
}) {
  return (
    <div className='address-table'>
      <Table
        data={data}
        title={t('sidebar.document')}
        subtitle={t('sidebar.waybill_query')}
        filter={filter}
        headers={headers.map((e) => (
          <th
            className={clsx('headers', e.className)}
            key={`thead-${Math.random()}`}
            style={{ width: e.width, left: e.left, right: e.right }}
          >
            {t(e.text)}
          </th>
        ))}
        buttons={buttons}
        rows={data?.docs?.map((e, index) => (
          <tr key={`table-row-${index}-${Math.random()}`}>
            {/* <td className='fixed-cell left'>
              <CheckBox
                padding='mr-0'
                onChange={() => onSelect(e)}
                checked={selectedData.find((s) => s._id === e._id)}
              />
            </td> */}
            <td className='fixed-cell left'>
              <span style={{ cursor: 'pointer' }} onClick={() => showDetail(e)}>
                {e.waybillnumber}
              </span>
            </td>
            <td style={{ left: 165 }}>{dayjs(e.shipping_date).format('DD/MM/YYYY')}</td>
            <td>{e.shipment_type?.name}</td>
            <td>{e.shipping_mode?.name}</td>
            <td>{e.delivery_mode}</td>
            <td>{e.custom_clearence?.name}</td>
            <td>{e.createdBranch?.name}</td>
            <td>{e.destination?.name}</td>
            <td>{e.address_code}</td>
            <td>{e.sender?.company}</td>
            <td>{e.sender?.name}</td>
            <td>{e.sender?.phone}</td>
            <td>{e.receiver?.company}</td>
            <td>{e.receiver?.name}</td>
            <td>{e.receiver?.phone}</td>
            <td>{getCargoType(e.item?.weight / e.item?.volume)}</td>
            <td>{e.item?.itemtype.name}</td>
            <td>{e.item?.itemname}</td>
            <td>{moneyDecimalFormatter(e.item?.qty)}</td>
            <td>{moneyDecimalFormatter(e.item?.weight)}</td>
            <td>{moneyDecimalFormatter(e.item?.volume)}</td>
            <td>{e.item?.item_value}</td>
            <td>{e.item?.remark}</td>
            <td>{e.cost?.currency?.name || 'CNY'}</td>
            <td>{moneyDecimalFormatter(customRound(e.cost?.total.toFixed(2)))}</td>
            <td>{moneyFormatter(customRound(e.remaining_balance?.toFixed(2)))}</td>
            <td style={{ right: 180 }}>{saleReport ? e.sale_person?.name : e.createdBy?.name}</td>
            <td style={{ right: 80 }}>{e.order_id ? dayjs(e.order_id?.shipping_date).format('DD/MM/YYYY') : '-'}</td>
            <td style={{ right: 0 }}>{e.currentStatus}</td>
            <td className='fixed-cell'>
              {canEdit() && (
                <Button style={{ width: 30 }} size='sm' onClick={() => onEdit(e)}>
                  <i className='fa fa-edit mr-2' />
                </Button>
              )}
              {canDelete() && (
                <Button
                  style={{ width: 30 }}
                  size='sm'
                  onClick={() => {
                    onDelete(e);
                  }}
                >
                  <i className='fa fa-trash mr-2' />
                </Button>
              )}
              <Button style={{ width: 30 }} size='sm' onClick={() => onToggle(e)}>
                <i className='fa fa-print' aria-hidden='true'></i>
              </Button>
            </td>
            <td></td>
          </tr>
        ))}
        footer={
          <>
            <td>-</td>
            <td>{t('waybill_entry.total')}</td>
            <td colSpan={16}></td>
            <td style={{ textAlign: 'right' }}>{data?.totalSum?.item?.qty}</td>
            <td style={{ textAlign: 'right' }}>{moneyDecimalFormatter(data?.totalSum?.item?.weight.toFixed(2))}</td>
            <td style={{ textAlign: 'right' }}>{moneyDecimalFormatter(data?.totalSum?.item?.volume.toFixed(2))}</td>
            <td></td>
            <td></td>
            <td></td>
            <td style={{ textAlign: 'right' }}>
              {' '}
              {moneyFormatter(customRound(data?.totalSum?.remaining_balance?.toFixed(2)))}
            </td>
            <td colSpan={4}></td>
          </>
        }
      />
      s
    </div>
  );
}

export default List;
