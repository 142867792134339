import React from 'react';
import './a5_en_print_style.scss';
// import logo from 'assets/img/brand/tag-bar-logo.jpg';
import companyLogo from 'assets/img/images/print_logo.png';
import Barcode from 'react-barcode';
import moment from 'moment';
import { getCargoType, moneyDecimalFormatter, customRound } from 'utli';
class A5ENPrintComponent extends React.Component {
  render() {
    const { data } = this.props;

    if (!data) return null;

    return (
      <div className={'Finance-Print'}>
        <div className={'Container'}>
          <div className={'LeftContainer'}>
            {/** Left Side */}
            <div className={'ContainerHeader'}>
              <div className={'Container'}>
                {/** Logo and Barcode */}
                <div className={'LogoContainer'}>
                  <img className={'Logo'} src={companyLogo} alt='Company Logo' style={{ width: 240, height: 80 }} />
                </div>
                <div className='BarCodeContainer'>
                  <h2>INVOICE</h2>
                  <Barcode
                    fontOptions='bolder'
                    value={data?.waybillnumber}
                    width={2}
                    height={50}
                    fontSize={34}
                    margin={3}
                  />
                </div>
              </div>

              <div className='CodesContainer'>
                <h4>Customer code: {data.address_code}</h4>
                <h4>Salesperson code: {data.sale_person?.name}</h4>
              </div>
            </div>
            <div className={'ContainerItem'}>
              <div className={'Container'}>
                {/**container */}
                <h4 className={'Header'}>
                  {/**header */}
                  Shipper Information
                </h4>
                <h4 className={'Header'}>
                  {/**header */}
                  Consignee Information
                </h4>
              </div>
              {/* <div className={'Divider'} /> */}
              <div className={'Container'}>
                {/**container */}
                <div className={'Item'}>
                  <p>{data?.sender?.company}</p>
                  <p>{data?.sender?.name}</p>
                  <p>{data?.sender?.phone}</p>
                  <p>{data?.sender?.address}</p>
                </div>
                <div className={'Item'}>
                  <p>{data?.receiver?.company}</p>
                  <p>{data?.receiver?.name}</p>
                  <p>{data?.receiver?.phone}</p>
                  <p>{data?.receiver?.address}</p>
                </div>
              </div>
            </div>
            <div className={'ContainerItem'}>
              <div className={'Container'}>
                {/**container */}
                <h4 className={'Header'}>
                  {/**header */}
                  Basic Information
                </h4>
              </div>
              {/* <div className={'Divider'} /> */}
              <div className={'Container'}>
                {/**container */}
                <div className={'Item'}>
                  <p>Departure branch: {data?.createdBranch?.name}</p>
                  <p>Shipping type: {data?.shipment_type?.name}</p>
                  <p>Delivery mode: {data?.delivery_mode ? data?.delivery_mode : '-'} </p>
                  <p>Transfer no: {data?.transfer_no ? data?.transfer_no : '-'} </p>
                  <p>Shipping date: {moment(Date.parse(data?.shipping_date)).format('DD-MM-YYYY')}</p>
                </div>
                <div className={'Item'}>
                  <p>Destination Branch: {data?.destination?.name}</p>
                  <p>Shipping mode: {data?.shipping_mode?.name}</p>
                  <p>Customs Clearence type：{data?.custom_clearence?.name}</p>
                  <p>Transfer company: {data?.transfer_company ? data?.transfer_company : '-'} </p>
                </div>
              </div>
            </div>
            <div className={'ContainerItem'}>
              <div className={'Container'}>
                {/**container */}
                <h4 className={'Header'}>
                  {/**header */}
                  Cargo Information
                </h4>
              </div>
              {/* <div className={'Divider'} /> */}
              <div className={'Container'}>
                {/**container */}
                <div className={'Item'}>
                  <p>Cargo name : {data?.item?.itemname === '' ? '-' : data?.item?.itemname}</p>
                  <p>Packing quantity : {data?.item?.qty}</p>
                  <p>
                    Measurement:
                    {data?.item?.volume === null ? '-' : data?.item?.volume}{' '}
                  </p>
                  <p>Cargo Group：{data?.item?.itemtype?.name}</p>
                </div>
                <div className={'Item'}>
                  <p>Weight(KGS) : {data?.item?.weight}</p>
                  <p>Cargo value: {moneyDecimalFormatter(data?.item?.item_value)}</p>
                  <p>Cargo type: {getCargoType(data?.item?.weight / data?.item?.volume)}</p>
                  <p>Remark: {data?.item?.remark}</p>
                </div>
              </div>
            </div>
            <div className={'ContainerItem'}>
              <div className={'Container'}>
                {/**container */}
                <h4 className={'Item'}>
                  {/**header */}
                  Cost Information
                </h4>
                <h4 className={'Item'}>Payment method: {data?.cost?.paymentType?.name}</h4>
              </div>
              <div className={'Divider'} />
            </div>
            {/* <div className={'Divider'} /> */}
            <div className={'FooterContainer'}>
              <div>
                <h4>Total Charges ({data?.cost?.currency?.name})</h4>
                <h4>Received Amount</h4>
                <h4>Remaining Balance</h4>
              </div>
              <div className={'DividerRow'}>
                <h4>:</h4>
                <h4>:</h4>
                <h4>:</h4>
              </div>
              <div>
                <h4>{moneyDecimalFormatter(customRound(data?.cost?.total?.toFixed(2)))}</h4>
                <h4>{moneyDecimalFormatter(customRound(data?.cost?.total) - customRound(data?.remaining_balance))}</h4>
                <h4>{moneyDecimalFormatter(customRound(data?.remaining_balance?.toFixed(2)))}</h4>
              </div>
              {/* <div className={'Total'}>
                <h4>Total Charges (CNY): &nbsp; {moneyDecimalFormatter(customRound(data?.cost?.total?.toFixed(2)))}</h4>
              </div>
              <div className={'Total'}>
                <h4>
                  Paid Amount: &nbsp;{' '}
                  {moneyDecimalFormatter(customRound(data?.cost?.total) - customRound(data?.remaining_balance))}
                </h4>
              </div>
              <div className={'Total'}>
                <h4>
                  Remaining Balance: &nbsp; {moneyDecimalFormatter(customRound(data?.remaining_balance?.toFixed(2)))}
                </h4>
              </div> */}
            </div>
            {/* <div className={'Footer'}>
              <h3 style={{ textAlign: 'center' }}>
                Payment method: {data?.cost.paymentType.name}
              </h3>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default A5ENPrintComponent;
