import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import Select from 'react-select';
import TextBox from 'components/Inputs/TextBox';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
function FilterBox({ onSearch }) {
  const customStyles = {
    control: (base) => ({
      ...base,
      height: 35,
      minHeight: 35,
    }),
  };

  const [searchType, setSearchType] = React.useState('name');
  const [search, setSearch] = React.useState('');
  const roles = useSelector((state) => state.role.roles);
  const roleOptions = roles.map((e) => {
    return {
      label: e.rolename,
      value: e._id,
    };
  });
  return (
    <Row>
      <Col md='5'>
        <Select
          styles={customStyles}
          defaultValue={{ value: 'name', label: t('name') }}
          onChange={(val) => {
            setSearch('');
            setSearchType(val.value);
          }}
          options={[
            { value: 'name', label: t('name') },
            { value: 'phonenumber', label: t('phonenumber') },
            { value: 'role', label: t('role') },
          ]}
        />
      </Col>
      <Col>
        {(searchType === 'name' || searchType === 'phonenumber') && (
          <TextBox onChange={(e) => setSearch(e.target.value)} style={{ height: 34 }} value={search} />
        )}
        {searchType === 'role' && (
          <Select
            styles={customStyles}
            options={roleOptions}
            onChange={(value) => setSearch(value.value)}
            value={roleOptions.find((b) => b._id === search)}
          />
        )}
      </Col>

      {searchType && (
        <Button
          size='sm'
          color='success'
          style={{ height: 35, position: 'relative', left: 0 }}
          onClick={() => {
            if (search) {
              setSearch(null);
              const searchData = {};
              searchData[searchType] = search;
              onSearch(searchData);
            }
          }}
        >
          <i className='fa fa-search'></i>
        </Button>
      )}
    </Row>
  );
}

export default FilterBox;
