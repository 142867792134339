import React from 'react';
import Table from 'components/Table';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { isExpired } from 'utli';
// import CheckBox from 'components/Inputs/CheckBox';
import { t } from 'i18next';
import { Button } from 'reactstrap';
function List({
  headers,
  // selectedData = [],
  data,
  // onSelect,
  started_way,
  title,
  subtitle,
  buttons,
  filter,
  showDetail,
  canEdit,
  onEdit,
  canDelete,
  onDelete,
  canLoad,
  onLoad,
}) {
  return (
    <div className='address-table'>
      <Table
        data={data}
        title={title}
        subtitle={subtitle}
        filter={filter}
        headers={headers.map((e) => (
          <th className={clsx('headers', e.className)} key={`thead-${Math.random()}`} style={{ width: e.width }}>
            {t(e.text)}
          </th>
        ))}
        buttons={buttons}
        rows={data?.map((e, index) => (
          <tr key={`table-row-${index}-${Math.random()}`} className={clsx(isExpired(e.expire_date) && 'deactive')}>
            {/* <td style={{ width: 40 }} className='fixed-cell left'>
              <CheckBox
                padding='mr-0'
                onChange={() => onSelect(e)}
                checked={selectedData.find((s) => s._id === e._id)}
              />
            </td> */}
            <td style={{ width: 40 }}>
              <span style={{ cursor: 'pointer' }} onClick={() => showDetail(e)}>
                {e.way_number}
              </span>
            </td>
            {/* <td style={{ width: 40 }}>{e.loaded_waybills && e.loaded_waybills[0]?.waybillnumber}</td> */}
            <td style={{ width: 40 }}>{dayjs(e.date).format('DD/MM/YYYY')}</td>
            {started_way !== 0 && <td style={{ width: 40 }}>{e.departure?.name}</td>}
            <td style={{ width: 40 }}>{e.destination?.name}</td>
            <td style={{ width: 40 }}>{e.container}</td>
            <td style={{ width: 40 }}>{e.createdBy?.name}</td>
            <td style={{ width: 40 }}>
              {canEdit() && (
                <Button
                  style={{ width: 40 }}
                  size='sm'
                  onClick={() => {
                    onEdit(e);
                  }}
                >
                  <i className='fa fa-edit' />
                </Button>
              )}
              {canLoad() && (
                <Button
                  style={{ width: 40 }}
                  size='sm'
                  onClick={() => {
                    onLoad(e);
                  }}
                >
                  <i className='fa fa-upload' />
                </Button>
              )}
              {canDelete() && (
                <Button
                  style={{ width: 40 }}
                  size='sm'
                  onClick={() => {
                    onDelete(e);
                  }}
                >
                  <i className='fa fa-trash' />
                </Button>
              )}
            </td>
          </tr>
        ))}
      />
    </div>
  );
}

export default List;
