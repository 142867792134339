import React from 'react';
import * as styles from './shipping_marker.module.css';

class ShippingMarkerPrint extends React.PureComponent {
  render() {
    const detail = this.props.data;

    if (!detail) return null;
    return (
      <div
        style={{
          display: 'none',
        }}
        id='pdf_print'
      >
        <div className={styles.container}>
          <table className={styles.package}>
            <tr>
              <th colSpan='2'>
                <h1 className={styles.first}>包装唛头 SHIPPING MARK</h1>
              </th>
            </tr>
            <tr>
              <td className={styles.second}>发货人 SHIPPER</td>
              <td>{detail?.sender?.name}</td>
            </tr>
            <tr>
              <td className={styles.second}>收货人 CONSIGNEE</td>
              <td>{detail?.receiver?.name}</td>
            </tr>
            <tr>
              <td className={styles.second}>目的地站点 DESTINATION BRANCH</td>
              <td>{detail?.destination?.name}</td>
            </tr>
            <tr>
              <td className={styles.second}>运输方式 SHIPPING MODE</td>
              <td>{detail?.shipping_mode?.name}</td>
            </tr>
            <tr>
              <td className={styles.second}>包装序号 PACKING SERIAL</td>
              <td></td>
            </tr>
            <tr>
              <td className={styles.second}>包装数量 PACKING QUANTITY</td>
              <td>{detail?.item?.qty}</td>
            </tr>
            <tr>
              <td colSpan='2'>
                <h1 className={styles.first}>{detail?.waybillnumber}</h1>
              </td>
            </tr>
            <tr>
              <td colSpan='2'>
                <span className={styles.third}>
                  {detail?.address_code} - {detail?.sale_person?._id?.name?.substr(0, 15)}
                </span>
              </td>
            </tr>
          </table>
          <div style={{ textAlign: 'left' }}>
            <span style={{ color: 'red' }}>{detail?.createdBranch?.address}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default ShippingMarkerPrint;
