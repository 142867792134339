import React, { useRef } from 'react';
import { Modal, ModalBody, Button, Col, FormGroup, Row, Form } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import List from './list';
import dayjs from 'dayjs';
import * as WayAction from 'store/actions/way';
import { t } from 'i18next';
import PrintPdfButton from './printPdfButton';
import { moneyFormatter } from 'utli';
import { remove_loaded_waybill } from 'services/way';
import LoadingModal from './loadModal';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import TextBox from 'components/Inputs/TextBox';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { getTracking } from 'services/waybill';
import { load as cargoLoad, edit_cargo_loading } from 'services/cargo';

function DetailDialog({ modalOpen, onToggle, data, started_way, load }) {
  const dispatch = useDispatch();
  const cargos = useSelector((state) => state.cargo.cargos);
  const [query, setQuery] = React.useState({});
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [deleteData, setDeleteData] = React.useState(false);
  const actionLoading = useSelector((state) => state.loading.actionLoading);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();
  const [actionFormLoading, setActionFormLoading] = React.useState(false);
  const headers = [
    'no',
    'waybill_entry.waybill',
    'address_book.code',
    'waybill_entry.receiver_name',
    'waybill_entry.item_name',
    'cargo_loading.total_qty',
    'waybill_entry.weight',
    'waybill_entry.volume',
    'waybill_entry.destination',
    'waybill_entry.remark',
    '',
  ];
  const scrollContainerRef = useRef(null);

  React.useEffect(() => {
    if (data) loadData();
  }, [WayAction.getWayCargo, query, data]);

  const loadData = () => {
    let queryParam = { limit: 10, page: 1 };
    if (query) {
      queryParam = query;
    }
    dispatch(WayAction.getWayCargo(data._id, queryParam));
  };

  const getTitle = () => {
    return `${data?.way_number}:${
      typeof data?.container === 'string' ? data?.container : data?.container?.name
    }(${dayjs(data?.date).format('DD/MM/YYYY')})`;
  };

  const submitData = async (values) => {
    if (!values.waybill_number || !values.qty) {
      NotificationManager.error('You have to fill data');
      return;
    }

    setActionFormLoading(true);
    const trackData = await getTracking(values.waybill_number.trim());
    if (!trackData) {
      NotificationManager.error('Waybill not found');
      setActionFormLoading(false);
      return;
    }

    if (trackData.currentStatus === 'ORDERCREATED') {
      NotificationManager.error('Waybill not found');
      setActionFormLoading(false);
      return;
    }

    const { waybill_info } = trackData;

    if (waybill_info.item.qty < Number(values.qty)) {
      // eslint-disable-next-line quotes
      NotificationManager.error("Qty is larger than waybill's qty");
      setActionFormLoading(false);
      return;
    }

    values.waybill_number = values.waybill_number.trim();

    const percentage = (Number(values.qty) / waybill_info.item.qty) * 100;
    const weight = Math.ceil((waybill_info.item.weight / 100) * percentage);
    const volume = ((waybill_info.item.volume / 100) * percentage).toFixed(2);

    if (editData) {
      await edit_cargo_loading({
        ...values,
        ...{
          volume,
          weight,
          way_id: data?._id,
          status: started_way ? 'LOADED' : 'UNLOADED',
        },
      });
      reset();
      setEditData(null);
    } else {
      await cargoLoad({
        ...values,
        ...{
          volume,
          weight,
          way_id: data?._id,
          status: started_way ? 'LOADED' : 'UNLOADED',
        },
      });
    }
    setActionFormLoading(false);
    reset();
    loadData();
  };

  const onEditCargo = async (data) => {
    setValue('waybill_number', data?.waybillnumber);
    setValue('qty', data?.qty);
    setValue('cargo_remark', data?.waybill?.remark);
    setEditData(data);

    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      size='lg'
      backdrop={true}
      style={{ top: '1%', maxWidth: '1150px', width: '80%', minWidth: 400 }}
    >
      <div
        style={{
          background: '#efeffe',
          height: 50,
          padding: 10,
          paddingLeft: 40,
        }}
        ref={scrollContainerRef}
      >
        <button
          aria-label='Close'
          className=' close'
          type='button'
          onClick={() => onToggle(!modalOpen)}
          style={{ position: 'absolute', top: 5, right: 20 }}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody style={{ paddingLeft: 10, paddingRight: 5 }}>
        <List
          load={load}
          headers={headers}
          data={cargos}
          extraContent={
            <div className='py-3'>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    marginBottom: '5px',
                    fontSize: 14,
                  }}
                >
                  <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.train_number')} :</div>{' '}
                  {data?.way_number}
                </div>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    marginBottom: '5px',
                    fontSize: 14,
                  }}
                >
                  <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.date')} : </div>
                  {dayjs(data?.date).format('DD / MM / YYYY')}
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                {started_way === 1 && (
                  <div
                    style={{
                      width: '50%',
                      display: 'flex',
                      marginBottom: '5px',
                      fontSize: 14,
                    }}
                  >
                    <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.departure_branch')} : </div>
                    {data?.departure?.name}
                  </div>
                )}
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    marginBottom: '5px',
                    fontSize: 14,
                  }}
                >
                  <div style={{ width: '50%', fontWeight: 'bold', fontSize: 14 }}>
                    {t('cargo_loading.destination_branch')} :{' '}
                  </div>
                  {data?.destination?.name}
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    marginBottom: '5px',
                    fontSize: 14,
                  }}
                >
                  <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.container')} :</div>
                  {data?.container}
                </div>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    marginBottom: '5px',
                    fontSize: 14,
                  }}
                >
                  <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.driver')} :</div>
                  {data?.driver}
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    marginBottom: '5px',
                    fontSize: 14,
                  }}
                >
                  <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.phone_number')} :</div>{' '}
                  {data?.phonenumber}
                </div>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    marginBottom: '5px',
                    fontSize: 14,
                  }}
                >
                  <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.driver_fee')}: </div>
                  {moneyFormatter(data?.driver_fee)}
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    marginBottom: '5px',
                    fontSize: 14,
                  }}
                >
                  <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.nrc')} :</div> {data?.nrc}
                </div>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    marginBottom: '5px',
                    fontSize: 14,
                  }}
                >
                  <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.advance_paid')}: </div>
                  {moneyFormatter(data?.advance_paid)}
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    marginBottom: '5px',
                    fontSize: 14,
                  }}
                >
                  <div style={{ width: '50%', fontWeight: 'bold' }}>{t('cargo_loading.note')} :</div> {data?.note}
                </div>
              </div>
            </div>
          }
          buttons={
            <Form
              onSubmit={handleSubmit(submitData)}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}
            >
              {load && (
                <Row>
                  <Col md='4'>
                    <FormGroup className={classnames('mb-0')}>
                      <TextBox
                        className='form-control'
                        placeholder={t('cargo_loading.waybill_no')}
                        type='text'
                        disabled={editData}
                        registerProps={register('waybill_number', {
                          required: 'Waybill No is required',
                        })}
                        errors={errors?.waybill_number}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='4'>
                    <FormGroup className={classnames('mb-0')}>
                      <TextBox
                        className='form-control'
                        placeholder={t('cargo_loading.qty')}
                        type='text'
                        registerProps={register('qty', {
                          required: 'Qty is required',
                        })}
                        errors={errors?.qty}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='4'>
                    <FormGroup className={classnames('mb-0')}>
                      <TextBox
                        className='form-control'
                        placeholder={t('cargo_loading.remark')}
                        type='text'
                        registerProps={register('cargo_remark')}
                        errors={errors?.remark}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              {!load && (
                <Button
                  style={{ width: 100, height: 40 }}
                  size='sm'
                  disabled={actionLoading}
                  onClick={() => {
                    dispatch(WayAction.exportWayCargo(data._id, getTitle()));
                  }}
                >
                  {actionLoading ? <i className='fa fa-spinner mr-2' /> : <i className='fa fa-file-excel mr-2' />}

                  {t('export')}
                </Button>
              )}
              {!load && (
                <PrintPdfButton
                  data={cargos}
                  wayInfo={data}
                  headers={headers}
                  started_way={started_way}
                  documentTitle={getTitle()}
                />
              )}

              {editData && (
                <Button style={{ width: 100, height: 40 }} size='sm' disabled={actionFormLoading} type='submit'>
                  Update
                </Button>
              )}

              {load && editData === null && (
                <Button style={{ width: 100, height: 40 }} size='sm' disabled={actionFormLoading} type='submit'>
                  {started_way === 1 ? t('load') : t('unload')}
                </Button>
              )}

              {load && (
                <div style={{ float: 'right' }}>
                  <Button
                    style={{ width: 100, height: 40 }}
                    size='sm'
                    disabled={actionLoading}
                    onClick={() => {
                      loadData();
                      reset();
                      setEditData(null);
                    }}
                  >
                    {t('refresh')}
                  </Button>
                </div>
              )}
            </Form>
          }
          subtitle=''
          onPageChange={(activePage, rowPerPage) => {
            setQuery({ limit: rowPerPage, page: activePage });
          }}
          onDeleteCargo={(data) => {
            setEditData(data);
            setDeleteData(true);
          }}
          onEditCargo={(data) => onEditCargo(data)}
        />
      </ModalBody>
      <LoadingModal
        started_way={started_way}
        modalOpen={loadingModal}
        editData={editData}
        onToggle={() => {
          setEditData(null);
          setLoadingModal(!loadingModal);
          loadData();
        }}
        way={data}
      />
      <ConfirmDialog
        modalOpen={deleteData}
        title={'Delete waybill from way'}
        body={`${t('confirm_delete')} ${editData?.waybillnumber}?`}
        onToggle={() => setDeleteData(false)}
        onConfirm={async () => {
          setDeleteData(false);
          setEditData(null);
          await remove_loaded_waybill(editData?.waybillnumber, data._id, started_way ? 'LOADED' : 'UNLOADED');
          await setTimeout(() => {
            loadData();
            dispatch(WayAction.getAll({}, started_way));
          }, 1000);
        }}
      />
    </Modal>
  );
}
export default DetailDialog;
