import * as WaybillService from 'services/waybill';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { SET_WAYBILLS, SET_WAYBILLS_REPORTS, SET_ADDITIONAL_STATUSES, SET_WAYBILL_LOGS } from 'store/types/waybill';
import { SET_WAYBILL_OPTIONS } from 'store/types/cargo';
const maxFileSize = 5242880; // 5 Mb

export const getAll = (query, body) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await WaybillService.getAll(query, body);
    if (query.saleReport) {
      dispatch({
        type: SET_WAYBILLS_REPORTS,
        payload: response,
      });
    } else {
      dispatch({
        type: SET_WAYBILLS,
        payload: response,
      });
    }

    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const getOptions = () => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await WaybillService.getOptions();
    dispatch({
      type: SET_WAYBILL_OPTIONS,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const getAdditionalStatus = (query, body) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await WaybillService.getAdditionalStatus(query, body);
    dispatch({
      type: SET_ADDITIONAL_STATUSES,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const getLog = (query, body) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    });
    let response = await WaybillService.getLog(query, body);
    dispatch({
      type: SET_WAYBILL_LOGS,
      payload: response,
    });
    dispatch({
      type: 'SET_LOADING',
      payload: false,
    });
  };
};

export const deleteImage = (file, waybillnumber, type) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await WaybillService.deleteImage(file, waybillnumber, type);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const save = (values, files, edit = false) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    const result = await WaybillService.save(values);
    const { orderImageFiles, deliveryImageFiles } = files;
    const fileTooLarge1 = orderImageFiles.find((img) => img.size > maxFileSize);

    const fileTooLarge3 = deliveryImageFiles.find((img) => img.size > maxFileSize);
    if (fileTooLarge1 || fileTooLarge3) {
      alert('file too large');
      return;
    }

    let formData = new FormData();

    orderImageFiles.forEach((img) => {
      formData.append('upload-all-order-file', img);
    });
    deliveryImageFiles.forEach((img) => {
      formData.append('upload-all-delivery-file', img);
    });

    await WaybillService.uploadFile(
      formData,
      values.waybillnumber ? values.waybillnumber : values.basicinfo.waybill_no
    );

    if (!edit && result)
      setTimeout(() => {
        window.location.reload();
      }, 1000);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const update = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await WaybillService.update(values);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteData = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await WaybillService.deleteData(id);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const saveAdditionalStatus = (values) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await WaybillService.saveAdditionalStatus(values);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteAllData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await WaybillService.deleteAllData(data);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const exportData = (data) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });

    const response = await WaybillService.exportWaybills(data);
    const blob = await response;

    const temp_date = dayjs(new Date()).format('DD/MM/YYYY');
    saveAs(blob, `waybill-${temp_date}.csv`);

    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};

export const deleteAdditionalStatus = (id) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: true,
    });
    await WaybillService.deleteAdditionalStatus(id);
    dispatch({
      type: 'SET_ACTION_LOADING',
      payload: false,
    });
  };
};
