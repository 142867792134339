import TextBox from 'components/Inputs/TextBox';
import React from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';
import { t } from 'i18next';
import DatePicker from 'components/Inputs/DatePicker';
import { update, deleteData } from 'services/transcation';
import { NotificationManager } from 'react-notifications';

export default function EditTransaction({ toggle, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();

  React.useEffect(() => {
    if (data) {
      setValue('reference_number', data?.reference_number);
      setValue('purchase_date', data?.purchase_date);
    } else {
      reset();
    }
  }, [data]);

  const submitData = async (values) => {
    reset();
    await update({
      ...values,
      _id: data?._id,
    });
    toggle();
  };

  const handleDelete = async () => {
    if (!data?._id) {
      NotificationManager.error('Cannot Delete');
    }
    await deleteData({
      _id: data?._id,
    });
    toggle();
  };

  return (
    <Form onSubmit={handleSubmit(submitData)} className='my-3 border-dark p-3 border'>
      <FormGroup className={classnames('mb-3')}>
        <label>{t('reference_number')}</label>
        <TextBox
          className='form-control'
          placeholder={t('reference_number')}
          type='text'
          registerProps={register('reference_number', {
            required: `${t('reference_number')} ${t('is_required')}`,
          })}
          errors={errors.reference_number}
          defaultValue={data?.reference_number}
        />
      </FormGroup>
      <FormGroup className={classnames('mb-3')}>
        <label>{t('purchase_date')}</label>
        <DatePicker
          onChange={(value) => {
            setValue('purchase_date', value);
          }}
          initialValue={watch('purchase_date') || data?.purchase_date}
        />
      </FormGroup>
      <FormGroup className={classnames('mb-3')}>
        <label>{t('paid_amount')}</label>
        <input className='form-control' value={data?.paid_amount} type='number' disabled={true} />
      </FormGroup>
      <div className='text-center'>
        <Button className='my-4' color='primary' type='submit'>
          {t('submit')}
        </Button>
        <Button className='my-4' color='warning' type='button' onClick={handleDelete}>
          {t('delete')}
        </Button>
      </div>
    </Form>
  );
}
