import React from 'react';
import { Row, Col } from 'reactstrap';
import dayjs from 'dayjs';
import ImageUploadContainer from '../../ImageUploadContainer';
import { getCargoType, customRound, moneyFormatter } from 'utli';
import './index.scss';
// import { HOST } from 'services/apiService';
import { t } from 'i18next';

function WaybillDetailContent({
  data,
  customItemName = '',
  // customPaymentMethod = '',
  // showSign = false,
  // signInfo,
  order_id,
}) {
  // return (
  //   <>
  //     <div>
  //       <h4
  //         style={{
  //           borderBottom: '1px solid rgb(108 108 138)',
  //           paddingBottom: 5,
  //           marginBottom: 10,
  //         }}
  //       >
  //         {t('waybill_entry.waybill')}
  //       </h4>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.waybill_no')}: {data?.waybillnumber}
  //         </span>
  //         <span>
  //           {t('waybill_entry.waybill_date')}:{' '}
  //           {dayjs(data?.shipping_date).format('DD/MM/YYYY')}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.operator')}: {data?.createdBy?.name}
  //         </span>
  //         <span>
  //           {t('waybill_entry.status')}: {data?.currentStatus}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.destination')}: {data?.destination.name}
  //         </span>
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.delivery_company')}: {data?.delivery_company}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.delivery_place')}: {data?.createdBranch?.name}
  //         </span>
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.delivery_no')}: {data?.delivery_no}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.sale_person')}: {data?.sale_person?.name}
  //         </span>
  //       </div>
  //     </div>
  //     <div className="mt">
  //       <div
  //         className="info-item"
  //         style={{
  //           borderBottom: '1px solid rgb(108 108 138)',
  //           paddingBottom: 5,
  //           marginBottom: 10,
  //         }}
  //       >
  //         <h4 style={{ width: '50%' }}>
  //           {t('address_book.sender')} {t('information')}
  //         </h4>
  //         <h4>
  //           {' '}
  //           {t('address_book.receiver')} {t('information')}
  //         </h4>
  //       </div>

  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('address_book.sender')} : {data?.sender?.name}
  //         </span>
  //         <span>
  //           {t('address_book.receiver')}: {data?.receiver?.name}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.phone')} : {data?.sender?.phone}
  //         </span>
  //         <span>
  //           {t('waybill_entry.phone')} : {data?.receiver?.phone}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }}>
  //           {t('waybill_entry.company')} : {data?.sender?.company}
  //         </span>
  //         <span>
  //           {' '}
  //           {t('waybill_entry.company')} : {data?.receiver?.company}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }}>
  //           {t('waybill_entry.address')} : {data?.sender?.address}
  //         </span>
  //         <span>
  //           {t('waybill_entry.address')} : {data?.receiver?.address}
  //         </span>
  //       </div>
  //     </div>
  //     <div className="mt-2">
  //       <div
  //         className="info-item"
  //         style={{
  //           borderBottom: '1px solid rgb(108 108 138)',
  //           paddingBottom: 5,
  //           marginBottom: 10,
  //         }}
  //       >
  //         <h4 style={{ width: '50%' }}>
  //           {t('waybill_entry.item_information')}
  //         </h4>
  //       </div>

  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.item_type')}:{' '}
  //           {data?.item?.itemtype?.name || customItemName}
  //         </span>
  //         <span>
  //           {' '}
  //           {t('waybill_entry.shipment_type')}: {data?.shipment_type?.name}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.item_name')} : {data?.item?.itemname}
  //         </span>
  //         <span>
  //           {t('waybill_entry.paymentType')}:{' '}
  //           {data?.cost.paymentType.name || customPaymentMethod}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.volume')}: (m3) : {data?.item?.volume}
  //         </span>
  //         <span>
  //           {' '}
  //           {t('waybill_entry.qty')}: : {data?.item?.qty}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.value')}: : {data?.item?.item_value}
  //         </span>
  //         <span>
  //           {' '}
  //           {t('waybill_entry.weight')}: (Kg) : {data?.item?.weight}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.cargo_type')}: :{' '}
  //           {getCargoType(data?.item?.weight / data?.item?.volume)}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('waybill_entry.remark')}: : {data?.item?.remark}
  //         </span>
  //       </div>
  //     </div>
  //     <div className="mt-2">
  //       <div
  //         className="info-item"
  //         style={{
  //           borderBottom: '1px solid rgb(108 108 138)',
  //           paddingBottom: 5,
  //           marginBottom: 10,
  //         }}
  //       >
  //         <h4 style={{ width: '50%' }}>
  //           {t('cargo_loading.sign_information')}
  //         </h4>
  //       </div>

  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('cargo_loading.signed_by')}: {signInfo?.receiver_name}
  //         </span>
  //         <span>
  //           {t('phonenumber')}: {signInfo?.receiver_phone}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('date')}: {dayjs(signInfo?.date).format('DD/MM/YYYY')}
  //         </span>
  //         <span className="mb-1">
  //           {t('cargo_loading.qty')}: {signInfo?.qty}
  //         </span>
  //       </div>
  //       <div className="info-item">
  //         <span style={{ width: '50%' }} className="mb-1">
  //           {t('cargo_loading.volume')}: {signInfo?.volume}
  //         </span>
  //         <span>
  //           {' '}
  //           {t('cargo_loading.weight')}: {signInfo?.weight}
  //         </span>
  //       </div>
  //       <div>
  //         <span>
  //           {signInfo?.sign_info?.imageUpload && (
  //             <div style={{ marginTop: '20px' }}>
  //               <img
  //                 style={{ width: 100, height: 100 }}
  //                 src={`${HOST}/static/${signInfo?.sign_info?.imageUpload}`}
  //               />
  //             </div>
  //           )}
  //         </span>
  //       </div>
  //     </div>

  //     <Row style={{ padding: 0, margin: 0, marginTop: 20 }}>
  //       <Col md="4">
  //         <ImageUploadContainer
  //           networkFiles={data?.order_file_names}
  //           showUpload={false}
  //           title={t('waybill_entry.order_file_upload')}
  //         />
  //       </Col>
  //       <Col md="4">
  //         <ImageUploadContainer
  //           networkFiles={data?.delivery_file_names}
  //           showUpload={false}
  //           title={t('waybill_entry.delivery_file_upload')}
  //         />
  //       </Col>
  //     </Row>
  //   </>
  // )
  return (
    <>
      <div>
        {!order_id && <h1 className='text-center'>{data?.waybillnumber}</h1>}
        {order_id && <h3 className='text-center'>Waybill Detail</h3>}
        <div className='d-flex mb-2' style={{ columnGap: '125px' }}>
          <h4>
            {t('waybill_entry.code')}: {data?.address_code}
          </h4>
          <h4>
            {t('waybill_entry.sale_person')}: {data?.sale_person?.name}
          </h4>
        </div>
      </div>
      <div className='mt-4'>
        <div
          className='info-item'
          style={{
            // borderBottom: '1px solid rgb(108 108 138)',
            paddingBottom: 5,
          }}
        >
          <h4 style={{ width: '50%' }}>
            {t('address_book.sender')} {t('information')}
          </h4>
          <h4>
            {' '}
            {t('address_book.receiver')} {t('information')}
          </h4>
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }}>
            {/* {t('waybill_entry.company')} : */}
            {data?.sender?.company}
          </span>
          <span>
            {' '}
            {/* {t('waybill_entry.company')} : */}
            {data?.receiver?.company}
          </span>
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }} className='mb-1'>
            {/* {t('address_book.sender')} :  */}
            {data?.sender?.name}
          </span>
          <span>
            {/* {t('address_book.receiver')}:  */}
            {data?.receiver?.name}
          </span>
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }} className='mb-1'>
            {/* {t('waybill_entry.phone')} :  */}
            {data?.sender?.phone}
          </span>
          <span>
            {/* {t('waybill_entry.phone')} :  */}
            {data?.receiver?.phone}
          </span>
        </div>

        <div className='info-item'>
          <span style={{ width: '50%' }}>
            {/* {t('waybill_entry.address')} :  */}
            {data?.sender?.address}
          </span>
          <span style={{ width: '50%' }}>
            {/* {t('waybill_entry.address')} :  */}
            {data?.receiver?.address}
          </span>
        </div>
      </div>
      <div className='mt-3'>
        <div
          className='info-item'
          style={{
            paddingBottom: 5,
          }}
        >
          <h4 style={{ width: '50%' }}>{t('waybill_entry.basic_information')}</h4>
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }}>
            {t('waybill_entry.departure')}: {data?.createdBranch?.name}
          </span>
          <span>
            {t('waybill_entry.destination')}: {data?.destination?.name}
          </span>
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }}>
            {' '}
            {t('waybill_entry.shipment_type')}: {data?.shipment_type?.name}
          </span>
          <span>
            {t('waybill_entry.shipping_mode')}: {data?.shipping_mode.name}
          </span>
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }}>
            {t('waybill_entry.delivery_mode')}:{data?.delivery_mode}
          </span>
          <span>
            {t('sidebar.custom_clearence')}:{data?.custom_clearence?.name}
          </span>
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }}>
            {t('waybill_entry.transfer_no')}:{data?.delivery_no}
          </span>
          <span>
            {t('waybill_entry.transfer_company')}: {data?.delivery_company ? data?.delivery_company : '-'}{' '}
          </span>
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }}>
            {t('waybill_entry.order_date')}: {dayjs(data?.shipping_date).format('DD/MM/YYYY')}
          </span>
        </div>
      </div>
      <div className='mt-3'>
        <div
          className='info-item'
          style={{
            // borderBottom: '1px solid rgb(108 108 138)',
            paddingBottom: 5,
          }}
        >
          <h4 style={{ width: '50%' }}>{t('waybill_entry.item_information')}</h4>
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }} className='mb-1'>
            {t('waybill_entry.item_name')} : {data?.item?.itemname}
          </span>
          {/* <span>
            {t('waybill_entry.paymentType')}:{' '}
            {data?.cost?.paymentType?.name || customPaymentMethod}
          </span> */}
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }} className='mb-1'>
            {' '}
            {t('waybill_entry.qty')}: : {data?.item?.qty}
          </span>
          <span>
            {' '}
            {t('waybill_entry.weight')}: (Kg) : {data?.item?.weight}
          </span>
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }} className='mb-1'>
            {t('waybill_entry.volume')}: (m3) : {data?.item?.volume}
          </span>
          <span>
            {t('waybill_entry.value')}: : {data?.item?.item_value}
          </span>
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }} className='mb-1'>
            {t('waybill_entry.item_type')}: {data?.item?.itemtype?.name || customItemName}
          </span>
          <span>
            {t('waybill_entry.cargo_type')}: : {getCargoType(data?.item?.weight / data?.item?.volume)}
          </span>
        </div>
        <div className='info-item'>
          <span style={{ width: '50%' }} className='mb-1'>
            {t('waybill_entry.remark')}: {data?.item?.remark}
          </span>
        </div>
      </div>
      <div className='mt-3'>
        <div
          className='info-item'
          style={{
            borderBottom: '1px solid rgb(108 108 138)',
            paddingBottom: 5,
          }}
        >
          <h4 style={{ width: '50%' }}>Cost Information</h4>
          <h4>
            {t('waybill_entry.paymentType')}: {data?.cost.paymentType.name}
          </h4>
        </div>
        {data?.cost?.insurance_fee > 0 && (
          <div
            className='info-item'
            style={{
              paddingBottom: 5,
            }}
          >
            <h4 style={{ width: '50%' }}>Insurance Fee</h4>
            <h4 style={{ textAlign: 'right' }}>{data?.cost?.insurance_fee}</h4>
          </div>
        )}
        {data?.cost?.prepaid_for_client > 0 && (
          <div
            className='info-item'
            style={{
              paddingBottom: 5,
            }}
          >
            <h4 style={{ width: '50%' }}>Prepaid for client</h4>
            <h4>{data?.cost?.prepaid_for_client}</h4>
          </div>
        )}
        {data?.cost?.pickup_fee > 0 && (
          <div
            className='info-item'
            style={{
              paddingBottom: 5,
            }}
          >
            <h4 style={{ width: '50%' }}>Pickup Fee</h4>
            <h4>{data?.cost?.pickup_fee}</h4>
          </div>
        )}
        {data?.cost?.clearence_fee > 0 && (
          <div
            className='info-item'
            style={{
              paddingBottom: 5,
            }}
          >
            <h4 style={{ width: '50%' }}>Clearance Fee</h4>
            <h4>{data?.cost?.clearence_fee}</h4>
          </div>
        )}
        {data?.cost?.storage_fee > 0 && (
          <div
            className='info-item'
            style={{
              paddingBottom: 5,
            }}
          >
            <h4 style={{ width: '50%' }}>Storage Fee</h4>
            <h4>{data?.cost?.storage_fee}</h4>
          </div>
        )}
        {data?.cost?.loading_fee > 0 && (
          <div
            className='info-item'
            style={{
              paddingBottom: 5,
            }}
          >
            <h4 style={{ width: '50%' }}>Loading Fee</h4>
            <h4>{data?.cost?.loading_fee}</h4>
          </div>
        )}
        {data?.cost?.behalf_of_collection > 0 && (
          <div
            className='info-item'
            style={{
              paddingBottom: 5,
            }}
          >
            <h4 style={{ width: '50%' }}>Behalf of Collection</h4>
            <h4>{data?.cost?.behalf_of_collection}</h4>
          </div>
        )}
        {data?.cost?.packaging_fee > 0 && (
          <div
            className='info-item'
            style={{
              paddingBottom: 5,
            }}
          >
            <h4 style={{ width: '50%' }}>Packaging Fee</h4>
            <h4>{Math.round(data?.cost?.packaging_fee)}</h4>
          </div>
        )}
        {data?.cost?.agent_fee > 0 && (
          <div
            className='info-item'
            style={{
              paddingBottom: 5,
            }}
          >
            <h4 style={{ width: '50%' }}>Agent Fee</h4>
            <h4>{Math.round(data?.cost?.agent_fee)}</h4>
          </div>
        )}
        {data?.cost?.basic_freight > 0 && (
          <div
            className='info-item'
            style={{
              paddingBottom: 5,
            }}
          >
            <h4 style={{ width: '50%' }}>Basic Freight</h4>
            <h4>{Math.round(data?.cost?.basic_freight)}</h4>
          </div>
        )}
        <div className='d-flex justify-content-end' style={{ paddingRight: '100px', paddingTop: '40px' }}>
          <h3>
            {t('waybill_entry.total_amount')} ({data?.cost?.currency?.name || 'CNY'}): &nbsp;{' '}
            {moneyFormatter(customRound(data?.cost?.total.toFixed(2)))}
          </h3>
        </div>
      </div>
      <Row style={{ padding: 0, margin: 0, marginTop: 20 }}>
        <Col md='4'>
          <ImageUploadContainer
            networkFiles={data?.order_file_names}
            showUpload={false}
            title={t('waybill_entry.order_file_upload')}
          />
        </Col>
      </Row>
    </>
  );
}
export default WaybillDetailContent;
