import React from 'react';
import { Modal, ModalBody, Row, Col, Button, FormGroup, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import TextBox from 'components/Inputs/TextBox';
import DatePicker from 'components/Inputs/DatePicker';
import SelectBox from 'components/SelectBox';
import { t } from 'i18next';

export default function AddDialog({ modalOpen, onToggle, onSubmit, title, editData, started_way }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      date: new Date(),
    },
  });

  const submitData = async (values) => {
    reset();
    onSubmit(values);
  };

  const branches = useSelector((state) => state.information.branches);
  let branchOptions = [];
  if (branches.map) {
    branchOptions = branches?.map((e) => {
      return {
        label: e.label,
        value: e._id,
      };
    });
  }

  React.useEffect(() => {
    if (editData) {
      setValue('date', editData?.date);
      setValue('driver', editData?.driver);
      setValue('way_no', editData?.way_number);
      setValue('destination_address', editData?.destination_address);
      setValue('container', editData?.container);
      setValue('driver_fee', editData?.driver_fee);
      setValue('phonenumber', editData?.phonenumber);
      setValue('note', editData?.note);
      setValue('nrc', editData?.nrc);
      setValue('advance_paid', editData?.advance_paid);
      setValue('departure', editData?.departure?._id);
      setValue('destination', editData?.destination?._id);
    } else {
      reset();
    }
  }, [editData]);

  return (
    <Modal isOpen={modalOpen} style={{ top: '15%', maxWidth: '60%' }} backdrop={true}>
      <div className=' modal-header pb-0'>
        <h5 className=' modal-title'>{title}</h5>
        <button aria-label='Close' className=' close' type='button' onClick={() => onToggle(!modalOpen)}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(submitData)}>
          {started_way === 1 && (
            <Row>
              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('date')}</label>
                  <DatePicker
                    onChange={(value) => {
                      setValue('date', value);
                    }}
                    initialValue={watch('date')}
                  />
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.container')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.container')}
                    type='text'
                    name='container'
                    errors={errors?.container}
                    registerProps={register('container', {
                      required: `${t('cargo_loading.container')} ${t('is_required')}`,
                    })}
                  />
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('waybill_entry.depature_branch')}</label>
                  <SelectBox
                    label={t('waybill_entry.depature_branch')}
                    control={control}
                    name='departure'
                    options={branchOptions}
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.driver')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.driver')}
                    type='text'
                    registerProps={register('driver')}
                    errors={errors?.driver}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('waybill_entry.destination')}</label>
                  <SelectBox
                    label={t('waybill_entry.destination')}
                    control={control}
                    name='destination'
                    options={branchOptions}
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.phone_number')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('phonenumber')}
                    type='text'
                    registerProps={register('phonenumber')}
                    errors={errors?.phonenumber}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.note')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.note')}
                    type='textarea'
                    rows='50'
                    registerProps={register('note')}
                    errors={errors?.note}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.nrc')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.nrc')}
                    type='text'
                    registerProps={register('nrc')}
                    errors={errors?.nrc}
                  />
                </FormGroup>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.driver_fee')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.driver_fee')}
                    type='text'
                    registerProps={register('driver_fee')}
                    errors={errors?.driver_fee}
                  />
                </FormGroup>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.advance_paid')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.advance_paid')}
                    type='number'
                    registerProps={register('advance_paid')}
                    errors={errors?.advance_paid}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          {started_way !== 1 && (
            <Row>
              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('date')}</label>
                  <DatePicker
                    onChange={(value) => {
                      setValue('date', value);
                    }}
                    initialValue={watch('date')}
                  />
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.container')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.container')}
                    type='text'
                    name='container'
                    errors={errors?.container}
                    registerProps={register('container', {
                      required: `${t('cargo_loading.container')} ${t('is_required')}`,
                    })}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.train_number')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.train_number')}
                    type='text'
                    registerProps={register('way_no', {
                      required: 'Way No is required',
                    })}
                    errors={errors?.way_no}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.driver')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.driver')}
                    type='text'
                    registerProps={register('driver')}
                    errors={errors?.driver}
                  />
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('waybill_entry.destination')}</label>
                  <SelectBox
                    label={t('waybill_entry.destination')}
                    control={control}
                    name='destination'
                    options={branchOptions}
                    rules={{ required: true }}
                  />
                </FormGroup>
              </Col>
              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.phone_number')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('phonenumber')}
                    type='text'
                    registerProps={register('phonenumber')}
                    errors={errors?.phonenumber}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.note')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.note')}
                    type='textarea'
                    rows='50'
                    registerProps={register('note')}
                    errors={errors?.note}
                  />
                </FormGroup>
              </Col>

              <Col md='6'>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.nrc')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.nrc')}
                    type='text'
                    registerProps={register('nrc')}
                    errors={errors?.nrc}
                  />
                </FormGroup>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.driver_fee')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.driver_fee')}
                    type='text'
                    registerProps={register('driver_fee')}
                    errors={errors?.driver_fee}
                  />
                </FormGroup>
                <FormGroup className={classnames('mb-3')}>
                  <label>{t('cargo_loading.advance_paid')}</label>
                  <TextBox
                    className='form-control'
                    placeholder={t('cargo_loading.advance_paid')}
                    type='number'
                    registerProps={register('advance_paid')}
                    errors={errors?.advance_paid}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}

          <div className='text-center'>
            <Button className='my-4' color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button className='my-4' color='primary' type='submit'>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
