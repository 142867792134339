import { sendData, sendForBlobData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getAll(query, value) {
  try {
    let response = await sendData(
      `cargo-action/get-cargo-to-sign?${new URLSearchParams(query).toString()}`,
      value,
      'POST',
      false
    );

    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getAllSigned(query, value) {
  try {
    let response = await sendData(`sign/get-all?${new URLSearchParams(query).toString()}`, value, 'POST', false);

    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function sign(values) {
  try {
    let response = await sendData('cargo-action/sign-confirmation', values, 'POST', false);

    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function exportData(query) {
  return await sendForBlobData('sign/export-query', query, {
    responseType: 'blob',
  });
}

export async function add_sign_query(data = {}) {
  return await sendData('sign/create', data);
}

export async function update_sign_query(data = {}) {
  return await sendData('sign/update', data);
}

export async function remove_sign_query_image(data = {}) {
  return await sendData('sign/remove_image', data);
}

export async function deleteData(id) {
  try {
    return await sendData('sign/delete-query', { _id: id });
  } catch (e) {
    NotificationManager.error(e.response.data.errors[0].msg);
    return false;
  }
}
