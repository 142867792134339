import React from 'react';
import { t } from 'i18next';
import Table from 'components/Table';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { isExpired, moneyDecimalFormatter, customRound } from 'utli';
import { Button } from 'reactstrap';
function List({
  headers,
  data,
  title,
  subtitle,
  buttons,
  filter,
  onPageChange,
  onWaybillClick,
  onChoose,
  canPay,
  onPay,
  onPrint,
}) {
  return (
    <div>
      <Table
        data={data}
        title={title}
        subtitle={subtitle}
        filter={filter}
        onPageChange={onPageChange}
        headers={headers.map((e) => (
          <th
            className={clsx('headers', e.className)}
            key={`thead-${Math.random()}`}
            style={{ width: e.width, left: e.left }}
          >
            {t(e.text)}
          </th>
        ))}
        buttons={buttons}
        rows={data?.docs?.map((e, index) => (
          <tr key={`table-row-${index}-${Math.random()}`} className={clsx(isExpired(e.expire_date) && 'deactive')}>
            {/* <td className='fixed-cell left'>
              <CheckBox
                padding='mr-0'
                onChange={() => onSelect(e)}
                checked={selectedData.find((s) => s._id === e._id)}
              />
            </td> */}
            <td className='fixed-cell left'>
              <span onClick={() => onWaybillClick(e)} style={{ cursor: 'pointer' }}>
                {e.waybillnumber}
              </span>
            </td>
            <td>{e.createdBranch?.name}</td>
            <td>{e.address_code}</td>
            <td>{e.sender?.company}</td>
            <td>{e.sender?.name}</td>

            <td>{e.receiver?.name}</td>
            <td>{dayjs(e.shipping_date).format('DD/MM/YYYY')}</td>
            <td>{e.has_signed ? 'Signed' : '-'}</td>
            <td>{e.sign_info ? dayjs(e.sign_info?.date).format('DD/MM/YYYY') : '-'}</td>
            <td>{e.cost?.currency?.name || 'CNY'}</td>
            <td style={{ textAlign: 'right' }}>{moneyDecimalFormatter(customRound(e?.cost?.total?.toFixed(2)))}</td>
            <td>{e.payment_group}</td>
            <td style={{ textAlign: 'right' }}>
              {moneyDecimalFormatter(customRound(e?.cost?.total - e.remaining_balance))}
            </td>
            <td style={{ textAlign: 'right' }}>
              {moneyDecimalFormatter(customRound(e.remaining_balance?.toFixed(2)))}
            </td>
            {!onChoose && (
              <td className='fixed-cell px-2'>
                {canPay() && (
                  <Button style={{ width: 30 }} size='sm' onClick={() => onPay(e)}>
                    <i className='fa fa-plus mr-2' />
                  </Button>
                )}
                <Button
                  style={{ width: 30 }}
                  size='sm'
                  onClick={() => {
                    onPrint(e);
                  }}
                >
                  <i className='fa fa-file-archive mr-2' />
                </Button>
              </td>
            )}
          </tr>
        ))}
      />
    </div>
  );
}

export default List;
