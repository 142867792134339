import { Col } from 'reactstrap';
import EditOrderAddressCode from './EditOrderAddressCode';

const EDIT_ORDER_ADDRESS_CODE = 'Update Order Address Code';

export default function Order() {
  return (
    <Col md={4}>
      <EditOrderAddressCode title={EDIT_ORDER_ADDRESS_CODE} />
    </Col>
  );
}
