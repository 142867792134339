import { useSelector } from 'react-redux';
const useActionPermission = (menu) => {
  const role = useSelector((state) => state.user.role);

  const canAdd = () => {
    return getPermission('create');
  };

  const canEdit = () => {
    return getPermission('edit');
  };

  const canDelete = () => {
    return getPermission('delete');
  };

  const canPay = () => {
    return getPermission('pay');
  };

  const canLoad = () => {
    return getPermission('load');
  };

  const canView = () => {
    return getPermission('view');
  };

  const canUnload = () => {
    return getPermission('unload');
  };

  const getPermission = (type) => {
    const foundPermission = role.role.action_permission.root.children.find((e) => e.name === menu);

    const showButton = foundPermission?.children.find((e) => e.name === type && e.status);

    return showButton;
  };
  return {
    canAdd,
    canEdit,
    canView,
    canDelete,
    canLoad,
    canUnload,
    getPermission,
    canPay,
  };
};

export default useActionPermission;
