import { getData, getForBlobData, sendData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';

export async function getAll(query) {
  try {
    let response = await getData(`transaction/get-all?${new URLSearchParams(query).toString()}`);

    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function exportData(query) {
  return await getForBlobData(`transaction/csv-export?${new URLSearchParams(query).toString()}`, {
    responseType: 'blob',
  });
}

export async function getTranscations(query) {
  try {
    let response = await getData(`transaction/get-transactions?${new URLSearchParams(query).toString()}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}
export async function update(data) {
  try {
    let response = await sendData('transaction/update-transaction', data, 'PATCH');

    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}
export async function deleteData(_id) {
  try {
    let response = await sendData('transaction/delete', { _id: _id });
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}
