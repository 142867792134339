import React, { useState, useRef } from 'react';
import { Button, Card, CardBody, Modal, ModalBody } from 'reactstrap';
import { getWayBillByWayBillNumber } from 'services/waybill';

const withEditWayBill = (WrappedComponent) => {
  return function EditWayBillWrapper({ title }) {
    const [isUpdateWayBillOpen, setIsUpdateWayBillOpen] = useState(false);
    const searchRef = useRef(null);
    const [editData, setEditData] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [clickedSearchBtn, setClickedSearchBtn] = useState(false);

    const toggleUpdateWayBill = () => {
      setIsUpdateWayBillOpen((prev) => !prev);
    };

    const searchWayBill = async (waybillNumber) => {
      if (!waybillNumber) return;
      setIsSearching(true);
      setClickedSearchBtn(true);
      const result = await getWayBillByWayBillNumber({ waybillnumber: waybillNumber });
      setEditData(result);
      setIsSearching(false);
    };

    const reset = () => {
      setEditData(null);
      toggleUpdateWayBill();
      setClickedSearchBtn(false);
    };

    const onClear = () => {
      setEditData(null);
      setClickedSearchBtn(false);
    };

    return (
      <>
        <Card onClick={toggleUpdateWayBill} color='primary' style={{ cursor: 'pointer' }}>
          <CardBody className='text-white'>{title}</CardBody>
        </Card>
        {isUpdateWayBillOpen && (
          <Modal isOpen={isUpdateWayBillOpen} style={{ top: '5%' }} backdrop={true} size='lg'>
            <div className=' modal-header'>
              <h5 className=' modal-title'>{title}</h5>
              <button aria-label='Close' className=' close' type='button' onClick={reset}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody>
              <div className='d-flex'>
                <input ref={searchRef} className='form-control' />
                <Button onClick={() => searchWayBill(searchRef.current.value)} color='danger'>
                  Search
                </Button>
                {editData && <Button onClick={onClear}>Clear</Button>}
              </div>
              {isSearching ? (
                <h3 className='m-3'>Searching...</h3>
              ) : (
                clickedSearchBtn && (
                  <>
                    {!editData ? (
                      <h3 className='m-3 text-danger'>No result found!</h3>
                    ) : (
                      <WrappedComponent toggle={reset} data={editData} />
                    )}
                  </>
                )
              )}
            </ModalBody>
          </Modal>
        )}
      </>
    );
  };
};

export default withEditWayBill;
