import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import * as InformationAction from 'store/actions/information';
import * as ContainerAction from 'store/actions/container';
import * as WayAction from 'store/actions/way';
import * as WayBillAction from 'store/actions/waybill';
import List from './../list';
import FilterBox from '../filter';
import { getHeaders } from './../header';
import DetailDialog from '../detailDialog';
import AddDialog from '../addDialog';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import { t } from 'i18next';
import useActionPermission from 'hooks/useActionPermission';
import useViewPermission from 'hooks/useViewPermission';

function CargoUnloading() {
  const dispatch = useDispatch();
  const router = useHistory();
  const end_ways = useSelector((state) => state.cargo.end_ways);
  const [searchValue, setSearchValue] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState([]);
  const [showDetail, setShowDetail] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [load, setLoad] = React.useState(false);
  const [addModal, setAddModal] = React.useState(false);
  const { isOwn, isBranch } = useViewPermission('cargo_unloading');
  const { canAdd, canEdit, canUnload, canDelete } = useActionPermission('cargo_unloading');

  const STARTED_WAY = 0;

  React.useEffect(() => {
    dispatch(InformationAction.getBranch());
    dispatch(ContainerAction.getAll({ started_way: STARTED_WAY }, false));
    dispatch(WayAction.getOptions(STARTED_WAY));
    dispatch(WayBillAction.getOptions());
  }, [dispatch, InformationAction.getBranch, ContainerAction.getAll]);

  React.useEffect(() => {
    loadData();
  }, [WayAction.getAll, router.location.search]);

  const loadData = (value = null) => {
    setSearchValue(value);
    const viewPermssion = {};

    if (isOwn()) viewPermssion.isOwn = !!isOwn();
    if (isBranch()) viewPermssion.isBranch = !!isBranch();

    dispatch(
      WayAction.getAll(
        value ? { ...value, ...viewPermssion } : searchValue ? { ...searchValue, ...viewPermssion } : viewPermssion,
        STARTED_WAY
      )
    );
    setSelectedData([]);
  };

  return (
    <>
      <List
        started_way={STARTED_WAY}
        headers={getHeaders(STARTED_WAY)}
        data={end_ways}
        selectedData={selectedData}
        canEdit={canEdit}
        onEdit={(e) => {
          if (!e.ended_way) {
            setAddModal(true);
            setEditData(e);
          } else {
            NotificationManager.error(t('cargo_loading.way_already_confirmed'));
          }
        }}
        canDelete={canDelete}
        onDelete={(e) => {
          setEditData(e);
          setShowDelete(true);
        }}
        canLoad={canUnload}
        onLoad={(e) => {
          setLoad(true);
          setEditData(e);
          setShowDetail(true);
        }}
        filter={
          <FilterBox
            onSearch={(value) => {
              setSelectedData([]);
              loadData(value);
            }}
            type='loading'
          />
        }
        onSelect={(e) => {
          if (selectedData.find((s) => s._id === e._id)) setSelectedData(selectedData.filter((s) => s._id !== e._id));
          else setSelectedData([...selectedData, e]);
        }}
        title={t('sidebar.cargo_diversion_center')}
        subtitle={t('sidebar.cargo_unloading')}
        showDetail={(e) => {
          setShowDetail(true);
          setEditData(e);
        }}
        buttons={
          <>
            {canAdd() && (
              <Button
                style={{ width: 100 }}
                size='sm'
                onClick={() => {
                  setEditData(null);
                  setAddModal(true);
                }}
              >
                <i className='fa fa-plus mr-2' /> {t('add')}
              </Button>
            )}
            {/* {canEdit() && (
              <Button
                style={{ width: 100 }}
                size='sm'
                disabled={selectedData.length !== 1}
                onClick={() => {
                  if (selectedData.length === 1) {
                    if (!selectedData[0].ended_way) {
                      setAddModal(true);
                      setEditData(selectedData[0]);
                    } else NotificationManager.error(t('cargo_loading.way_already_confirmed'));
                  } else {
                    NotificationManager.error(t('cargo_loading.choose_one_way_edit'));
                  }
                }}
              >
                <i className='fa fa-edit mr-2' /> {t('edit')}
              </Button>
            )} */}
            {/* {canDelete() && (
              <Button
                style={{ width: 100 }}
                size='sm'
                disabled={selectedData.length !== 1}
                onClick={() => {
                  if (selectedData.length === 1) {
                    if (selectedData[0].loaded_waybills?.length > 0) {
                      NotificationManager.error('Cannot delete. Waybills are loaded');
                      return;
                    }
                    setEditData(selectedData[0]);
                    setShowDelete(true);
                  }
                }}
              >
                <i className='fa fa-trash mr-2' /> {t('delete')}
              </Button>
            )} */}
            <Button
              style={{ width: 100 }}
              size='sm'
              onClick={() => {
                loadData({});
                setSelectedData([]);
                if (searchValue) window.location.reload();
                else router.push('/cargo-unloading');
              }}
            >
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
            <Button
              style={{ width: 100 }}
              size='sm'
              onClick={() => {
                const viewPermssion = {};

                if (isOwn()) viewPermssion.isOwn = !!isOwn();
                if (isBranch()) viewPermssion.isBranch = !!isBranch();

                dispatch(
                  WayAction.exportData(searchValue ? { ...searchValue, ...viewPermssion } : viewPermssion, STARTED_WAY)
                );

                setSelectedData([]);
              }}
            >
              <i className='fa fa-file mr-2' /> {t('export')}
            </Button>
            {/* {canUnload() && (
              <Button
                style={{ width: 100 }}
                size='sm'
                onClick={() => {
                  if (selectedData.length === 1) {
                    setLoad(true);
                    setEditData(selectedData[0]);
                    setShowDetail(true);
                  } else {
                    NotificationManager.error(t('cargo_loading.choose_one_way_load'));
                  }
                }}
              >
                <i className='fa fa-upload mr-2' /> {t('unload')}
              </Button>
            )} */}
          </>
        }
      />
      <DetailDialog
        modalOpen={showDetail}
        onToggle={() => {
          setShowDetail(!showDetail);
          setLoad(false);
        }}
        load={load}
        data={editData}
        started_way={STARTED_WAY}
      />
      <AddDialog
        started_way={STARTED_WAY}
        modalOpen={addModal}
        onToggle={() => {
          setAddModal(!addModal);
        }}
        title={editData ? 'Edit Way' : t('cargo_loading.create_way_for_train_number')}
        editData={editData}
        onSubmit={(values) => {
          setAddModal(false);
          if (editData) dispatch(WayAction.update({ ...values, _id: editData?._id }));
          else dispatch(WayAction.save({ ...values, started_way: STARTED_WAY }));
          setTimeout(() => loadData(), 1000);
        }}
      />

      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('cargo_loading.train_number')}`}
        body={`${t('confirm_delete')} ${editData?.way_number}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(WayAction.deleteData(editData?._id));
          setTimeout(() => loadData(), 1000);
        }}
      />
    </>
  );
}

export default CargoUnloading;
