import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import queryString from 'query-string';
import FilterBox from './filter';
import * as InformationAction from 'store/actions/information';
import * as TranscationAction from 'store/actions/transcation';
import * as CollectionAction from 'store/actions/collection';
import * as PaymentTypeAction from 'store/actions/payment_type';
import { getWaybillByNumber } from 'services/waybill';
import List from './list';
import { getHeaders } from './header';
import { t } from 'i18next';
import DetailDialog from 'pages/waybill/query/detailDialog';
import useViewPermission from 'hooks/useViewPermission';
import * as EmployeeAction from 'store/actions/employee';

function Transcation() {
  const dispatch = useDispatch();
  const router = useHistory();
  const transcations = useSelector((state) => state.waybill.transcations);
  const [searchValue, setSearchValue] = React.useState(null);
  //const [selectedData, setSelectedData] = React.useState(null);
  const [editData, setEditData] = React.useState(null);
  const [showDetail, setShowDetail] = React.useState(false);
  const actionLoading = useSelector((state) => state.loading.actionLoading);
  const { isOwn, isBranch } = useViewPermission('document');

  React.useEffect(() => {
    dispatch(InformationAction.getBranch());
    dispatch(CollectionAction.getAll({}));
    dispatch(PaymentTypeAction.getAll({}));
    dispatch(EmployeeAction.getMarketingPerson());
  }, [dispatch, InformationAction.getBranch]);

  React.useEffect(() => {
    loadData();
  }, [TranscationAction.getAll, router.location.search]);

  const loadData = (value = null) => {
    setSearchValue(value);
    let query = { limit: 10, page: 1 };
    if (router.location.search) query = queryString.parse(router.location.search);

    if (value) {
      query = { limit: 10, page: 1 };
    }

    if (isOwn()) query.isOwn = !!isOwn();
    if (isBranch()) query.isBranch = !!isBranch();

    const body = value ? value : searchValue ? searchValue : {};

    dispatch(TranscationAction.getAll({ ...query, ...body }));
  };

  const handleExportData = () => {
    let query = {};

    if (searchValue) {
      query = searchValue;
    }

    if (isOwn()) query.isOwn = !!isOwn();
    if (isBranch()) query.isBranch = !!isBranch();
    dispatch(TranscationAction.exportData(query));
  };

  return (
    <>
      <List
        headers={getHeaders()}
        data={transcations}
        //selectedData={selectedData}
        // onSelect={(e) => {
        //   if (selectedData.find((s) => s._id === e._id)) setSelectedData(selectedData.filter((s) => s._id !== e._id));
        //   else setSelectedData([...selectedData, e]);
        // }}
        onWaybillClick={async (e) => {
          const waybill = await getWaybillByNumber(e.waybill_number);
          setEditData(waybill.data);
          setShowDetail(true);
        }}
        filter={
          <FilterBox
            onSearch={(value) => {
              //setSelectedData([]);
              loadData(value);
            }}
          />
        }
        buttons={
          <>
            <Button
              style={{ width: 100 }}
              size='sm'
              onClick={() => {
                loadData({});
                //setSelectedData([]);
                router.push('/transaction-records');
              }}
            >
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
            {/* <PrintTranscation selectedData={selectedData} /> */}
            <Button style={{ width: 100 }} size='sm' disabled={actionLoading} onClick={() => handleExportData()}>
              {actionLoading ? <i className='fa fa-spinner mr-2' /> : <i className='fa fa-file-excel mr-2' />}
              {t('export')}
            </Button>
          </>
        }
      />
      <DetailDialog
        data={editData}
        modalOpen={showDetail}
        onToggle={() => setShowDetail(false)}
        customItemName={editData?.item?.itemtype?.name}
        customPaymentMethod={editData?.cost.paymentType.name}
      />
    </>
  );
}

export default Transcation;
