import { getData, sendData, sendForBlobData } from './apiService';
import NotificationManager from 'react-notifications/lib/NotificationManager';
export async function getAll(query, value) {
  try {
    let response = await sendData(
      `waybill/search?total=true&${new URLSearchParams(query).toString()}`,
      value,
      'POST',
      false
    );
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getAdditionalStatus(query, value) {
  try {
    let response = await sendData(
      `waybill/get-comments?&${new URLSearchParams(query).toString()}`,
      value,
      'POST',
      false
    );
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function save(values) {
  try {
    return await sendData('waybill/create', values);
  } catch (e) {
    NotificationManager.error(e.response.data.message);
    return false;
  }
}

export async function uploadFile(files, waybillnumber) {
  try {
    return await sendData(`files/waybill-images?waybillnumber=${waybillnumber}`, files, 'POST', false);
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function deleteData(_id) {
  try {
    let response = await sendData(`waybill/${_id}`, null, 'DELETE');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function deleteAllData(data) {
  try {
    let response = await sendData('waybill', data, 'DELETE');
    return response;
  } catch (e) {
    NotificationManager.error(JSON.stringify(e.response.data.message));
    return false;
  }
}

export async function update(values) {
  try {
    return await sendData('address-book/update/' + values._id, values, 'PUT');
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function fetchWaybillNumber() {
  try {
    let response = await getData('order/request-waybill-number');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function deleteImage(file, waybillnumber, type) {
  try {
    return await sendData(
      `files/waybill-image-delete-custom?file_name=${file}&waybill_id=${waybillnumber}&type=${type}`,
      {},
      'DELETE'
    );
  } catch (e) {
    NotificationManager.error(e.response.data.error);
    return false;
  }
}

export async function exportWaybills(data) {
  return await sendForBlobData('waybill/export-waybill', data, {
    responseType: 'blob',
  });
}

export async function saveAdditionalStatus(data) {
  try {
    let response = await sendData('waybill/save-comment', data);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function deleteAdditionalStatus(id) {
  try {
    let response = await sendData(`waybill/comment/${id}`, {}, 'DELETE');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getTracking(waybill_number) {
  try {
    let response = await getData(`frontend/get-waybill-tracking?waybill_number=${waybill_number}`);
    return response;
  } catch (e) {
    return false;
  }
}

export async function getWaybillByNumber(waybill_number) {
  try {
    let response = await getData(`waybill/find-one/${waybill_number}`);
    return response;
  } catch (e) {
    return false;
  }
}

export async function getLog(waybill_number) {
  try {
    let response = await getData(`waybill/get-log?waybill_number=${waybill_number}`);
    return response;
  } catch (e) {
    return false;
  }
}

export async function getTranscationWaybill(waybill) {
  try {
    let response = await getData(`transaction/get-transcation-count?waybillnumber=${waybill}`);
    return response;
  } catch (e) {
    return false;
  }
}

export async function getWaybillCargoCount(waybill_number) {
  try {
    let response = await getData('waybill/get-cargo-count/' + waybill_number);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getOptions() {
  try {
    let response = await getData('waybill/get-option');
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}

export async function getWayBillByWayBillNumber(query) {
  try {
    let response = await getData(`waybill/get-waybill?${new URLSearchParams(query).toString()}`);
    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}
export async function updateWayBill(data) {
  try {
    let response = await sendData('waybill/update-waybill', data, 'PATCH');

    return response;
  } catch (e) {
    NotificationManager.error('Opps. Something wrong');
    return false;
  }
}
