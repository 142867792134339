import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as InformationAction from 'store/actions/information';
import { Button } from 'reactstrap';
import List from './list';
import AddDialog from './addDialog';
import DeleteAllConfirm from './deleteAllConfirm';
import DetailDialog from './detailDialog';
import OrderFormExport from './orderFormExport';
import ShippingMaker from './ShippingMaker';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import FilterBox from './filter';
import * as EmployeeAction from 'store/actions/employee';
import * as OrderAction from 'store/actions/order';
import { useHistory } from 'react-router';
import { getHeaders } from './headers';
import queryString from 'query-string';
import * as CustomClearenceAction from 'store/actions/customClearence';
import { NotificationManager } from 'react-notifications';
import PrintDialog from './printDialog';
import { t } from 'i18next';
import useActionPermission from 'hooks/useActionPermission';
import useViewPermission from 'hooks/useViewPermission';

function OrderQuery() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [editData, setEditData] = React.useState(null);
  const [showDelete, setShowDelete] = React.useState(false);
  const [showDetail, setShowDetail] = React.useState(false);
  const [showDeleteAll, setShowDeleteAll] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState(null);
  const [selectedData, setSelectedData] = React.useState([]);

  const { canAdd, canEdit, canDelete } = useActionPermission('order');

  const dispatch = useDispatch();
  const router = useHistory();

  const [openPrintDialog, setOpenPrintDialog] = React.useState(false);
  const onToggle = (e) => {
    setEditData(e);
    setOpenPrintDialog(!openPrintDialog);
  };

  const orders = useSelector((state) => state.waybill.orders);
  const actionLoading = useSelector((state) => state.loading.actionLoading);

  const { isOwn, isBranch } = useViewPermission('order');

  React.useEffect(() => {
    dispatch(InformationAction.getBranch());
    dispatch(EmployeeAction.getMarketingPerson());
    dispatch(CustomClearenceAction.getAll({}, true));
  }, [dispatch, InformationAction.getBranch]);

  React.useEffect(() => {
    loadData(searchValue);
  }, [OrderAction.getAll, router.location.search, searchValue]);

  const loadData = (value = null) => {
    setSearchValue(value);
    let query = { limit: 10, page: 1 };
    if (router.location.search) query = queryString.parse(router.location.search);

    if (isOwn()) query.isOwn = !!isOwn();
    if (isBranch()) query.isBranch = !!isBranch();

    dispatch(OrderAction.getAll(query, value ? value : searchValue ? searchValue : {}));
  };

  return (
    <>
      <List
        headers={getHeaders()}
        selectedData={selectedData}
        data={orders}
        onToggle={(e) => onToggle(e)}
        canEdit={canEdit}
        canDelete={canDelete}
        onEdit={(e) => {
          if (e.is_used) {
            NotificationManager.error(t('waybill_entry.already_used'));
          } else {
            setEditData(e);
            setTimeout(() => {
              setModalOpen(true);
            }, 1000);
          }
        }}
        onDelete={(e) => {
          setEditData(e);
          setTimeout(() => {
            setShowDelete(true);
          }, 1000);
        }}
        onSelect={(e) => {
          if (selectedData.find((s) => s._id === e._id)) setSelectedData(selectedData.filter((s) => s._id !== e._id));
          else setSelectedData([...selectedData, e]);
        }}
        showDetail={(e) => {
          setEditData(e);
          setShowDetail(true);
        }}
        filter={
          <FilterBox
            onSearch={(value) => {
              setSelectedData([]);
              loadData(value);
            }}
          />
        }
        buttons={
          <>
            {canAdd() && (
              <Button
                style={{ width: 100 }}
                size='sm'
                onClick={() => {
                  setModalOpen(true);
                  setEditData(null);
                }}
              >
                <i className='fa fa-plus mr-2' />
                {t('add')}
              </Button>
            )}
            <Button
              style={{ width: 100 }}
              size='sm'
              disabled={actionLoading}
              onClick={() => {
                const query = {};
                if (isOwn()) query.isOwn = !!isOwn();
                if (isBranch()) query.isBranch = !!isBranch();
                dispatch(OrderAction.exportData(searchValue ? { ...searchValue, ...query } : query));
              }}
            >
              {actionLoading ? <i className='fa fa-spinner mr-2' /> : <i className='fa fa-file-excel mr-2' />}

              {t('export')}
            </Button>
            <Button
              style={{ width: 100 }}
              size='sm'
              onClick={() => {
                window.location = '/order-query';
              }}
            >
              <i className='fa fa-spinner mr-2' /> {t('refresh')}
            </Button>
          </>
        }
      />
      <AddDialog
        title={editData ? `${t('edit')} ${t('sidebar.order')}` : `${t('add')} ${t('sidebar.order')}`}
        editData={editData}
        modalOpen={modalOpen}
        onToggle={(open) => setModalOpen(open)}
        onSubmit={(values, uploadFiles) => {
          if (editData) {
            values.original_waybill_id = editData._id;
            values.waybillnumber = editData.basicinfo?.waybill_no;
          }

          dispatch(OrderAction.save(values, uploadFiles));
        }}
      />
      <ConfirmDialog
        modalOpen={showDelete}
        title={`${t('delete')} ${t('sidebar.order')}`}
        body={`${t('confirm_delete')} ${editData?.waybillnumber}?`}
        onToggle={() => setShowDelete(false)}
        onConfirm={() => {
          setShowDelete(false);
          dispatch(OrderAction.deleteData(editData?._id));
          setTimeout(() => loadData(), 1000);
        }}
      />
      <DeleteAllConfirm
        data={selectedData}
        modalOpen={showDeleteAll}
        title={`${t('delete')} ${t('sidebar.order')}`}
        body={`${t('confirm_delete')}?`}
        onToggle={() => setShowDeleteAll(false)}
        onConfirm={() => {
          setShowDeleteAll(false);
          setSelectedData([]);
          dispatch(OrderAction.deleteAllData({ waybill_id_list: selectedData }));
          setTimeout(() => loadData(), 1000);
        }}
        onRemoveSelected={(e) => {
          setSelectedData(selectedData.filter((s) => s._id !== e._id));
        }}
      />
      <DetailDialog
        data={editData}
        customItemName={editData?.item?.itemtype?.name}
        customPaymentMethod={editData?.cost?.paymentType?.name}
        modalOpen={showDetail}
        onToggle={() => setShowDetail(false)}
      />
      <PrintDialog onToggle={onToggle} modalOpen={openPrintDialog}>
        <div style={{ textAlign: 'center' }}>
          <OrderFormExport selectedData={editData} />
          <h5>Print</h5>
        </div>
        <div style={{ textAlign: 'center' }}>
          <ShippingMaker selectedData={editData} />
          <h5>Marker</h5>
        </div>
      </PrintDialog>
    </>
  );
}

export default OrderQuery;
