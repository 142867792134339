import React from 'react';
import { Modal, ModalBody, Button, Form, Row, Col } from 'reactstrap';
// import * as EmployeeAction from 'store/actions/employee';
// import { getCountryOption } from 'store/actions/location';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
// import NumberInput from 'components/Inputs/NumberInput';
import './add-dialog.scss';
import TextBox from 'components/Inputs/TextBox';
import DatePicker from 'components/Inputs/DatePicker';
import SelectBox from 'components/SelectBox';
import { t } from 'i18next';
import { customRound } from 'utli';
import { NotificationManager } from 'react-notifications';

export default function AddDialog({ title, modalOpen, onToggle, onSubmit, data }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control,
    getValues,
    watch,
  } = useForm();

  const [currency, setCurrency] = React.useState('');

  const currencies = useSelector((state) => state.other_maintenance.currencies);

  const currencyOptions = currencies?.map((e) => {
    return {
      label: e.name,
      value: e.name,
    };
  });

  React.useEffect(() => {
    setValue('paid_in_currency', customRound(data?.remaining_balance));
    setValue('billingAmount', 0);
    setValue('exchangeRate', 1);
    setValue('remaining_balance', customRound(data?.remaining_balance));
    setValue('name', data?.receiver?.name);
    setValue('phonenumber', data?.receiver?.phone);
    setCurrency(currencies[0]?.name);
  }, [data, currencies]);

  const submitData = async (values) => {
    if (Number(values.billingAmount) > customRound(Number(getValues('paid_in_currency')))) {
      NotificationManager.error('Received and discount is greather than remaning balance');
      return;
    }

    reset();
    values.purchase_date = values.purchase_date || new Date();
    if (!values.exchangeRate) {
      values.exchangeRate = 0;
    }

    if (!values.paid_in_currency) {
      values.paid_in_currency = values.paidAmount;
    }

    setCurrency('');
    onSubmit(values);
  };

  const collections = useSelector((state) => state.other_maintenance.collections);
  const collectionTypesOptions = collections?.map((e) => {
    return {
      label: e.name,
      value: e._id,
    };
  });

  return (
    <Modal
      isOpen={modalOpen}
      size='lg'
      style={{
        maxWidth: '600px',
        width: '75%',
        minWidth: 400,
      }}
      backdrop={true}
    >
      <ModalBody style={{ maxHeight: '90vh', overflowY: 'auto' }} className='finance-modal'>
        <Form onSubmit={handleSubmit(submitData)} className='pb-5'>
          <h2 className='mb-3'>{title}</h2>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.biller_name')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                className='form-control'
                placeholder={t('finance.biller_name')}
                type='text'
                registerProps={register('name', {
                  required: `${t('finance.biller_name')} ${t('is_required')}`,
                })}
                errors={errors.name}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.biller_phonenumber')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                className='form-control'
                placeholder={t('finance.biller_phonenumber')}
                type='text'
                registerProps={register('phonenumber', {
                  required: `${t('finance.biller_phonenumber')} ${t('is_required')}`,
                })}
                errors={errors.phonenumber}
              />
            </Col>
          </Row>
          <Row>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.purchase_date')}</label>
            </Col>
            <Col md='8'>
              <DatePicker
                onChange={(date) => {
                  setValue('purchase_date', date);
                }}
                initialValue={new Date()}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.reference_number')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                className='form-control'
                placeholder={t('finance.reference_number')}
                type='text'
                registerProps={register('reference_number')}
                errors={errors.reference_number}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.collection_type')}</label>
            </Col>
            <Col md='8'>
              <SelectBox
                options={collectionTypesOptions}
                label={t('finance.collection_type')}
                control={control}
                name='collection'
                rules={{ required: true }}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.invoice_amount')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                className='form-control'
                outputPrepend={data?.cost?.currency?.name}
                value={getValues('paid_in_currency')}
                disabled={true}
                onChange={(e) => {
                  const paidInCurrency = e.target.value;
                  const billingAmount = getValues('billingAmount') || 0;

                  const remaining_balance = parseFloat(paidInCurrency) - parseFloat(billingAmount);

                  setValue('remaining_balance', customRound(remaining_balance));
                  setValue('paid_in_currency', paidInCurrency);
                }}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.currency')}</label>
            </Col>
            <Col md='8'>
              <Row className='mb-3'>
                <Col xs={6}>
                  <SelectBox
                    options={currencyOptions}
                    label={t('finance.currency')}
                    control={control}
                    name='currency'
                    defaultValue={currencyOptions[0]?.value}
                    rules={{ required: true }}
                    onValueChange={(value) => {
                      setCurrency(value);
                    }}
                  />
                </Col>
                <Col xs={6}>
                  <div className='d-flex align-items-center'>
                    <label className='mr-2'>{t('finance.exchange_rate')}</label>
                    <TextBox
                      className='form-control'
                      placeholder={t('finance.exchange_rate')}
                      type='number'
                      step='any'
                      registerProps={register('exchangeRate')}
                      defaultValue={0}
                      errors={errors.exchangeRate}
                      onChange={(e) => {
                        const paidAmount = getValues('paidAmount') || 0;
                        const discount = getValues('discount') || 0;
                        const exhangeRate = e.target.value || 1;
                        const paidInCurrency = getValues('paid_in_currency');

                        const billingAmount = (parseFloat(paidAmount) + parseFloat(discount)) / parseFloat(exhangeRate);

                        const remaining_balance = parseFloat(paidInCurrency) - billingAmount;

                        setValue('billingAmount', customRound(billingAmount));
                        setValue('remaining_balance', customRound(remaining_balance));
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.paid_amount')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                outputPrepend={currency}
                className='form-control'
                placeholder={t('finance.paid_amount')}
                type='text'
                registerProps={register('paidAmount', {
                  required: `${t('finance.paid_amount')} ${t('is_required')}`,
                })}
                onChange={(e) => {
                  const paidAmount = e.target.value;
                  const discount = getValues('discount') || 0;
                  const exhangeRate = getValues('exchangeRate') || 1;
                  const paidInCurrency = getValues('paid_in_currency');

                  const billingAmount = (parseFloat(paidAmount) + parseFloat(discount)) / parseFloat(exhangeRate);

                  setValue('billingAmount', customRound(billingAmount));
                  setValue('remaining_balance', customRound(paidInCurrency) - billingAmount);
                }}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.discount')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                outputPrepend={currency}
                className='form-control'
                placeholder={t('finance.discount')}
                type='text'
                registerProps={register('discount')}
                defaultValue={0}
                onChange={(e) => {
                  const paidAmount = getValues('paidAmount') || 0;
                  const discount = e.target.value || 0;
                  const exhangeRate = getValues('exchangeRate') || 1;

                  const billingAmount = (parseFloat(paidAmount) + parseFloat(discount)) / parseFloat(exhangeRate);

                  const paidInCurrency = customRound(getValues('paid_in_currency'));

                  setValue('billingAmount', customRound(billingAmount));
                  setValue('remaining_balance', customRound(parseFloat(paidInCurrency) - billingAmount));
                }}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.billing_amount')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                outputPrepend={data?.cost?.currency?.name || 'CNY'}
                className='form-control'
                placeholder={t('finance.billing_amount')}
                type='text'
                registerProps={register('billingAmount')}
                defaultValue={0}
                disabled={true}
              />
            </Col>
          </Row>

          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>{t('finance.remaining_balance')}</label>
            </Col>
            <Col md='8'>
              <TextBox
                outputPrepend={data?.cost?.currency?.name || 'CNY'}
                className='form-control'
                placeholder={t('finance.remaining_balance')}
                value={customRound(watch('remaining_balance'))}
                disabled={true}
              />
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md='4' style={{ textAlign: 'right', marginTop: 5 }}>
              <label>Remark</label>
            </Col>
            <Col md='8'>
              <TextBox
                className='form-control'
                placeholder={'Remark'}
                type='text'
                registerProps={register('remark')}
                errors={errors.remark}
              />
            </Col>
          </Row>

          <div className='custom-modal-footer text-center'>
            <Button color='success' type='button' onClick={() => onToggle(!modalOpen)}>
              {t('cancel')}
            </Button>
            <Button color='primary' type='submit'>
              {t('submit')}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
}
